import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/mauritius-shipping-map.jpg'
import CountryImage from './../../assets/single-elements/blog-images/mauritius-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToMauritius extends Component {

  render() {
    const title = "Importation of vehicles to Mauritius from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Mauritius"
    const pubDate = "7 May - 5 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Import a car from UK to Mauritius</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a vehicle from the UK to Mauritius" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" style={{ textAlign: 'center' }}>
                    <BlogBlueprint
                      title={title}
                      bImage={CountryImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      We supply brand new and pre-owned vehicles from the UK to our clients based in Mauritius.
                      CHICARGOS is rated as the #1 vehicle exporter to the African Island.
                    </p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      To order a vehicle from our site, browse the available stock <a className="a-links" href="/">using the search feature here </a>
                      <br/>
                      OR
                      <br/>
                      Submit an import enquiry request below for a quote on any vehicle available on the UK market.

                      <br/>
                      Vehicles are either shipped in containers or using the ‘roll on roll off’ (RORO) option. CHICARGOS will collate and prepare
                      all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                      Marine Insurance Documents, Receipts and any other documents required for shipping, and process these on your behalf
                    </p>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px' }}> Car shipping service to Port Louis, Mauritius from UK</p>

                    <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>

                      <p>
                        The main harbor of the nation is located in Port Louis, it is managed by Mauritius Ports Authority (MPA).
                        CHICARGOS offers a shipped and insured service to the following ports:
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Port Louis: </span>
                        Customer is responsible for picking up the vehicle at the port
                      </p>
                      <div style={{
                            marginTop: "25px",
                            height: '350px',
                            backgroundImage: `url(${bImage})`
                          }} className="help-department-heading"/>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Importing a vehicle from the UK takes approximately 21-24 days;
                      we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                      CHICARGOS will arrange for Bureau Veritas to inspect the vehicle (cost £380).
                      After a successful inspection we will then ship the vehicle and send relevant documentation via DHL.
                    </p>
                    <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                      Documentation required at importation by the Mauritius Revenue Authority (MRA) for clearance purposes:
                    </p>
                    <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                      <ul>
                        <li>
                          Bureau Veritas stamped V5C Logbook
                        </li>
                        <li>
                          Bill of Lading
                        </li>
                        <li>
                          Invoice or letter of sale indicating the price paid
                        </li>
                        <li>
                          Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                          These may include certificate of registration and police clearance certificate.
                        </li>
                        <li>
                          Spare key (if available)
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Mauritius </p>
                    <p className="page-information"
                       style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      <span style={{fontWeight: 700}}>The following should be regarded as a guide only, </span>
                      as the final assessment is given by the Mauritius Revenue Authority (MRA) at the time of clearance
                      at the border.
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '10px'}}>
                      The duty to be paid on importation of motor vehicles into Mauritius is based on the value of the
                      vehicle
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      Before you can register your car in Mauritius, you have to pay Excise Duty (between 15% to 100%), and VAT.
                    </p>

                    <div>
                      <br/>
                      <br/>
                      <h4 className="page-header-content" style={{ color: '#b60811'}}>FAQs</h4>
                      <div style={{ color: '#424242'}}>
                        <p style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>How much does it cost to ship a
                          car from UK to Port Louis, Mauritius?</p>
                        <div style={{fontSize: '15px', color: '#424242'}}>Shipping charge is calculated based on the
                          size of the vehicle. Use the table below for reference.
                        </div>
                        <div className="container" style={{fontSize: '15px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Vehicle Type</th>
                              <th>Port Louis</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Saloon
                              </td>
                              <td>from $1450</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                SUV, 4x4 (Pick Up)
                              </td>
                              <td>from $1860.00</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Van
                              </td>
                              <td>from $2000</td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <p style={{fontSize: '16px', fontWeight: 700}}>How much are vehicle import duties and taxes in Mauritius?</p>
                        <div style={{fontSize: '15px'}}>
                          The following should be regarded as a guide only, as the final assessment is given by the Mauritius Revenue Authority(MRA)
                          at the time of clearance at the border. <br/> <span style={{fontWeight: 700}}>VAT of 15% </span> is payable on the sum of vehicle value, exercise duty
                          and freight charges. Excise duty is calculated as follows:
                          <div className="container" style={{fontSize: '15px', paddingTop: '20px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Conventional motor cars:</th>
                              <th>Import Excise Duty</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                  Up to 550 cc
                                </td>
                                <td>0%</td>
                              </tr>
                              <tr>
                                <td style={{borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                  551 - 1,000 cc
                                </td>
                                <td>45%</td>
                              </tr>
                              <tr>
                                <td style={{borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                  1,001 - 1,600 cc
                                </td>
                                <td>50%</td>
                              </tr>
                              <tr>
                                <td style={{borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                  1,601 - 2,000 cc
                                </td>
                                <td>75%</td>
                              </tr>
                              <tr>
                                <td style={{borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                  Above 2,000 cc
                                </td>
                                <td>100%</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                        </div>

                      </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px'}}>Quick Import enquiry request </p>
                    <div>
                      <ImportRequestForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

}

export default ImportCarToMauritius;
