import React from "react";
import {Button, Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1] + " " + arrayOfString[2];
};

const routeToDetails = (props) => {
  const { history } = props;
  history.push(`${props.d.id}`);

};

const PromoElements = (props) => {
   return(
     <div onClick={() => routeToDetails(props)} className="col-lg-6 col-md-6 col-6 mt-4"
          style={{marginBottom: '1.5rem', cursor: 'pointer'}} key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive card-img-top-promo"
           src={props.d.remote_image}
           alt="Card image cap"
         />
         <CardBody style={{ textAlign: 'center', paddingBottom: '0px'}}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle style={{ paddingBottom: '10px', fontSize: '15px'}}>
               {/*eslint-disable-next-line*/}
               <div className="link-text">
                 {stringSorten(props.d.title)}
               </div>{' '}
             </CardSubtitle>
           </div>

         </CardBody>
       </Card>
       {/*<hr style={{width: '80%'}}/>*/}
      </div>
    );

}

export default withRouter(PromoElements);