import {
  GET_VEHICLE_MAKE_COUNT,
  SEARCH_VEHICLE,
  GET_PRODUCTS,
  GET_COUNTRIES,
  GET_SPECIFICATIONS,
  GET_PRICE,
  ADD_PRODUCT_TO_BASKET,
  // GET_BEST_DEALS,
  GET_PROMOTIONAL_API_DATA,
  GET_VEHICLE, SEARCH_ITEM,
  GET_STOCK_RECORD, GET_SHIPPING_METHODS, PLACE_ORDER, GET_ITEM, POST_CONTENT, GET_PRODUCT_TYPE,
  CREATE_PRODUCT, GET_CONTENT, GET_VENDOR_PRODUCTS, CREATE_LECTURE, UPLOAD_IMAGE, GET_ALL_PRODUCTS, DELETE_CONTENT
} from "../constants";
import { Observable } from "rxjs/Rx";
import { vehicleAction} from "../actions/index";
import { HttpService } from "../../services/http";
import path from "../../config/path";

export default class vehicleEpic {
  // Get Vehicle Make Count
  static getVehicleMakeCount = action$ =>
    action$.ofType(GET_VEHICLE_MAKE_COUNT).switchMap(() => {
      return HttpService.get(`${path.VEHICLE_MAKE_COUNT}`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getVehicleMakeCountSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(
            vehicleAction.getVehicleMakeCountFailure(`${err}`)
          );
        });
    });

  // Search Vehicle
  static searchVehicle = action$ =>
    action$.ofType(SEARCH_VEHICLE).switchMap(({ payload }) => {
      const priceRange =
        payload.max - payload.min > 0
          ? `&price__range=${payload.min}__${payload.max}`
          : "";

      const order = payload.order === "0" ? "price" : "-price"
      let finalPayload = `?ordering=${order}&page=${payload.page}${priceRange}&search=${
        payload.st
      }&search_simple_query_string=${payload.sv}&title__wildcard=${
        payload.svm === "any" || payload.svm === "0"
          ? ""
          : payload.svm.toLowerCase()
      }`;


      return HttpService.get(`${path.SEARCH_VEHICLE}${finalPayload}`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.searchVehicleSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.searchVehicleFailure(`${err}`));
        });
    });

  // Search Item
  static searchItem = action$ =>
    action$.ofType(SEARCH_ITEM).switchMap(({ payload }) => {
      let payloadParameters = `?q=${payload.q}&min=${payload.min}&max=${payload.max}&page=${payload.page}&sort=${payload.sort}`
      if (payload.q === 'cat-search') {
        payloadParameters = `?q=${payload.q}&type=${payload.type}&min=${payload.min}&max=${payload.max}&page=${payload.page}&sort=${payload.sort}`
      }
      return HttpService.get(`${path.SEARCH_ITEM}/${payloadParameters}`)
        .switchMap(response=> {
          if (response.status === 200){
            return Observable.of(
              vehicleAction.searchItemSuccess(response.response)
            );
          }
          if (response.status === 204) {
            return Observable.of(
              vehicleAction.searchItemSuccess({status: 204})
            );
          }
        })
        .catch(err => {
          return Observable.of(
            vehicleAction.searchItemFailure(`${err}`));
        })
    })

  // Get Products
  static getProducts = action$ =>
    action$.ofType(GET_PRODUCTS).switchMap(({ payload }) => {
      return HttpService.get(`${path.GET_PRODUCTS}/${payload}`)
        .switchMap(response => {
          if (response.status === 200) {
             let priceURL = response.response.price
            if (process.env.REACT_APP_API_ENDPOINT === 'prod') {
              priceURL = priceURL.replace('http', 'https');
            }
            return Observable.of(
              vehicleAction.getProductsSuccess(response.response),
              vehicleAction.getCountries(payload),
              vehicleAction.getSpecifications(payload),
              vehicleAction.getPrice(priceURL)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getProductsFailure(`${err}`));
        });
    });

  // Get All Products
  static getAllProducts = action$ =>
    action$.ofType(GET_ALL_PRODUCTS).switchMap(({ payload }) => {
      let payloadParameters = `${path.GET_ALL_PRODUCTS}/?q=${payload}`
      return HttpService.get(payloadParameters)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getAllProductsSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getAllProductsFailure(`${err}`))
        });
  });


  // Get Countries
  static getCountries = action$ =>
    action$.ofType(GET_COUNTRIES).switchMap(() => {
      return HttpService.get(`${path.GET_COUNTRIES}/`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getCountriesSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getCountriesFailure(`${err}`));
        });
    });

  // Get Specifications
  static getSpecifications = action$ =>
    action$.ofType(GET_SPECIFICATIONS).switchMap(({ payload }) => {
      return HttpService.get(`${path.GET_SPECIFICATIONS}/${payload}`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getSpecificationsSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(
            vehicleAction.getSpecificationsFailure(`${err}`)
          );
        });
    });

  // Get Price
  static getPrice = action$ =>
    action$.ofType(GET_PRICE).switchMap(({ payload }) => {
      return HttpService.get(`${payload}`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getPriceSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getPriceFailure(`${err}`));
        });
    });

  // Get Stock Record
  static getStockRecord = action$ =>
    action$.ofType(GET_STOCK_RECORD).switchMap(({ payload }) => {
      return HttpService.get(`${payload}`)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getStockRecordSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getStockRecordFailure(`${err}`));
        });
    });

  // Add Product To Basket
  static addProductToBasket = action$ =>
    action$.ofType(ADD_PRODUCT_TO_BASKET).switchMap(({ payload }) => {
      const token = localStorage.getItem('token')
      return HttpService.post(path.ADD_PRODUCT_TO_BASKET, payload.endpointAndQuantity, {"Authorization": "Token "+ token})
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.addProductToBasketSuccess(response.response)
            );
          }
        })
        .catch(err => {
          if (err.status === 500) {
            return Observable.of(vehicleAction.addProductToBasketFailure(
              "Oops, something has gone wrong. Contact us quoting reference number 500 "
            ))
          }
          if (err.response.reason === "Unavailable" ){
            return Observable.of(vehicleAction.addProductToBasketFailure("Item is unavailable"))
          }
          return Observable.of(
            vehicleAction.addProductToBasketFailure(`${err}`)
          );
        });
    });

  // Place Order
  static placeOrder = action$ =>
    action$.ofType(PLACE_ORDER).switchMap(({ payload }) => {
     return HttpService.post(path.PLACE_ORDER, payload.dataPayload, {"Authorization": "Token "+ payload.sessionToken})
       .switchMap(response => {
         if (response.status === 200 ) {
           return Observable.of(
             vehicleAction.placeOrderSuccess(response.response)
           );
         }
       })
       .catch(err => {
         return Observable.of(
           vehicleAction.placeOrderFailure(`${err}`)
         );
       });
    });

  // Get Promotional Api Data
  static getPromotionalApiData = action$ =>
    action$.ofType(GET_PROMOTIONAL_API_DATA).switchMap(() => {
      return HttpService.get(path.GET_PROMOTIONAL_API_DATA)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getPromotionalApiDataSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(
            vehicleAction.getPromotionalApiDataFailure({ errMess: err })
          );
        });
    });


  // Get Shipping methods data
  static getShippingMethods = action$ =>
    action$.ofType(GET_SHIPPING_METHODS).switchMap(() => {
      return HttpService.get(path.GET_SHIPPING_METHODS)
        .switchMap(response => {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getShippingMethodsSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(
            vehicleAction.getShippingMethodsFailure({ errMess: err })
          );
        });
    });

  // Get Product Details
  static getVehicle = action$ =>
    action$.ofType(GET_VEHICLE).switchMap(({ payload }) => {
      return HttpService.get(`${path.GET_VEHICLE_DETAILS}/${payload}/`)
        .switchMap(response => {
          if (response.status === 200) {
            let priceURL = response.response.price
            let stockURL = response.response.stockrecords
            if (process.env.REACT_APP_API_ENDPOINT === 'prod') {
              priceURL = priceURL.replace('http', 'https');
              stockURL = stockURL.replace('http', 'https');
            }
            return Observable.of(
              vehicleAction.getVehicleSuccess(response.response),
              vehicleAction.getPrice(priceURL),
              vehicleAction.getStockRecord(stockURL)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getVehicleFailed(`${err}`));
        });
    })

    // Get Item Details
  static getItem = action$ =>
    action$.ofType(GET_ITEM).switchMap(({ payload }) => {
      let payloadParameters = `?q=${payload}&type=gsi`
      return HttpService.get(`${path.SEARCH_ITEM}/${payloadParameters}`)
        .switchMap(response=> {
          if (response.status === 200){
            return Observable.of(
              vehicleAction.getItemSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getItemFailure(`${err}`));
        })
    })

  // Post Content
  static postContent = action$ =>
    action$.ofType(POST_CONTENT).switchMap(({ payload }) => {
      return HttpService.post(path.ADD_CONTENT, payload, )
        .switchMap(response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.postContentSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.postContentFailure(`${err}`));
        })
    })

  // Get Content?order_by=created
  static getContent = action$ =>
    action$.ofType(GET_CONTENT).switchMap(({ payload }) => {
      let endpoint = `${path.GET_CONTENT}/?cid=${payload.cid}`
      if (payload.lid !== undefined) {
        endpoint = `${path.GET_CONTENT}/?cid=${payload.cid}&lid=${payload.lid}`
      }
      return HttpService.get(endpoint )
        .switchMap(response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getContentSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getContentFailure(`${err}`));
        })
    })

  // Get Product Types
  static getProductType = action$ =>
    action$.ofType(GET_PRODUCT_TYPE).switchMap(({ payload }) => {
      return HttpService.get(path.GET_PRODUCT_TYPES)
        .switchMap( response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getProductTypeSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.getProductTypeFailure(`${err}`))
        })
    })

  // Get Product Level
  static createProduct = action$ =>
    action$.ofType(CREATE_PRODUCT).switchMap(({ payload }) => {
      const token = localStorage.getItem('token')
      let authData = {Authorization: `token ${token}`}
      let headers = {
        "Content-Type": "application/json",
        ...authData
      }
      return HttpService.post(path.CREATE_PRODUCT, payload, headers)
        .switchMap( response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.createProductSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.createProductFailure(`${err}`))
        })
    })

  // Get vendor products
  static getVendorProducts = action$ =>
    action$.ofType(GET_VENDOR_PRODUCTS).switchMap(({payload}) => {
      let headers = {
        "Content-Type": "application/json",
        ...payload
      }

      return HttpService.get(path.GET_VENDOR_PRODUCTS, headers)
        .switchMap(response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.getVendorProductsSuccess(response.response)
            );
          }
        })
        .catch( err => {
          return Observable.of(vehicleAction.getVendorProductsFailure(`${err}`))
        })
    })

  // Create lecture
  static createLecture = action$ =>
    action$.ofType(CREATE_LECTURE).switchMap(({ payload }) => {
      const token = localStorage.getItem('token')
      let authData = {Authorization: `token ${token}`}
      let headers = {
        "Content-Type": "application/json",
        ...authData
      }
      return HttpService.post(path.CREATE_LECTURE, payload, headers)
        .switchMap( response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.createLectureSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.createLectureFailure(`${err}`))
        })
    })

  static uploadImage = action$ =>
    action$.ofType(UPLOAD_IMAGE).switchMap(({ payload }) => {
      const token = localStorage.getItem('token')
      let authData = {Authorization: `token ${token}`}
      let myHeaders = {
        ...authData
      }
      return HttpService.post(path.UPLOAD_IMAGE, payload, myHeaders)
        .switchMap( response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.uploadImageSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.uploadImageFailure(`${err}`))
        })
    })

  static deleteContent = action$ =>
    action$.ofType(DELETE_CONTENT).switchMap(({ payload }) => {
      const token = localStorage.getItem('token')
      let authData = {Authorization: `token ${token}`}
      let myHeaders = {
        ...authData
      }
      return HttpService.delete(path.DELETE_CONTENT, payload, myHeaders)
        .switchMap( response=> {
          if (response.status === 200) {
            return Observable.of(
              vehicleAction.deleteContentSuccess(response.response)
            );
          }
        })
        .catch(err => {
          return Observable.of(vehicleAction.deleteContentFailure(`${err}`))
        })
    })
}
