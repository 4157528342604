import TextField from "@mui/material/TextField";
import {Editor} from "react-draft-wysiwyg";
import React from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// function uploadCallback(file) {
//   return new Promise(
//     (resolve, reject) => {
//       resolve({ data: { link: "http://dummy_image_src.com" } });
//     }
//   );
// }
const RteTemplate = ({lectureName,
                       handleLectureNameChange,
                       editorState,
                       onEditorStateChange,
                       lectureCount,
                       eventName,
                       rteName,
                       lectureTitle,
                       handleLectureTitleChange
                     }) => {

  return (
    <div style={{padding: "24px"}}>
      <div className="App">
        <TextField
          fullWidth
          id="lectureTitle"
          label="Lecture Title"
          value={lectureTitle}
          onChange={handleLectureTitleChange}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          style={{marginBottom: "32px", backgroundColor: "white"}}
        />
        <TextField
          fullWidth
          id={eventName}
          label="Enter what the student will learn"
          value={lectureName}
          onChange={handleLectureNameChange}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          style={{marginBottom: "32px", backgroundColor: "white"}}
        />
        <header className="App-header">
          <h5 style={{fontWeight: 800}}>{lectureName}</h5>
        </header>
        <Editor editorState={editorState}
                id={rteName}
                // toolbar={{
                //   embedded:{
                //     embedCallback: embedVideoCallBack
                //   }
                // }}

                onEditorStateChange={(e) => onEditorStateChange(e, rteName)}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                wrapperId="banana"
                // plugins={plugins}
                toolbar={{
                  inline: {inDropdown: true},
                  list: {inDropdown: true},
                  textAlign: {inDropdown: true},
                  link: {inDropdown: true},
                  // history: {inDropdown: true},
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    // uploadCallback: uploadCallback,
                    previewImage: true,
                    alt: { present: false, mandatory: false }
                  },
                }}
        />
      </div>
    </div>
  )
}

export default RteTemplate;