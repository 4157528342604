import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/namibia-shipping-map.jpg'
import CountryImage from './../../assets/single-elements/blog-images/namibia-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToNamibia extends Component {

  render() {
    const title = "Importation of vehicles to Namibia from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Namibia"
    const pubDate = "10 May - 5 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Shipping a car from UK to Namibia</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a vehicle from the UK to Namibia" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page blogs-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" >
                    <BlogBlueprint
                      title={title}
                      bImage={CountryImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Supplier of brand new and pre-owned vehicles from the UK to our clients based in Namibia.
                      CHICARGOS is rated as the #1 vehicle exporter to the African country.
                    </p>
                    <br/>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      To order a vehicle, browse the available stock <a className="a-links" href="/">
                      using the search feature here </a>
                      <br/>
                      OR
                      <br/>
                      Submit an import enquiry request below for a quote on any vehicle available on the UK market.
                      <br/>
                    </p>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px' }}>
                      Car shipping service to Windhoek, Namibia from UK</p>

                    <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>

                      <p>
                        Cars are either shipped in containers or using the ‘roll on roll off’ (RORO) option.
                        CHICARGOS will collate and prepare
                        all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                        Marine Insurance Documents, Receipts and any other documents required for shipping, and process
                        these on your behalf
                      </p>
                      <p>
                        The main harbour is in Walvis Bay thus CHICARGOS offers a shipped and insured service to the
                        following ports:
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Walvis Bay: </span>
                        Customer is responsible for picking up the vehicle from the port
                      </p>
                      <div style={{
                            marginTop: "25px",
                            height: '350px',
                            backgroundImage: `url(${bImage})`
                          }} className="help-department-heading"/>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Importing a vehicle from the UK takes approximately 21-24 days;
                      we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                      CHICARGOS will ship the vehicle and send relevant documentation via DHL.
                    </p>
                    <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                      Documentation required at importation by the Namibian Authority for clearance purposes:
                    </p>
                    <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                      <ul>
                        <li>
                          Immigrants must produce their permanent residence permit issued by the Ministry of Home Affairs
                        </li>
                        <li>
                          Returning Namibians must produce proof of emigration from Namibia. proof of permanent residence
                          obtained abroad, as well as evidence that such permanent residence has been withdrawn
                        </li>
                        <li>
                          A duly completed form NA 304 A
                        </li>
                        <li>
                          Bill of lading
                        </li>
                        <li>
                          Invoice or letter of sale indicating the price paid
                        </li>
                        <li>
                          Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                          These may include certificate of registration and police clearance certificate.
                        </li>
                        <li>
                          Spare key (if available)
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Namibia </p>
                    <p className="page-information"
                       style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      <span style={{fontWeight: 700}}>The following should be regarded as a guide only, </span>
                      as the final assessment is given by the Namibian Revenue Authorities at the time of clearance
                      at the border.
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '10px'}}>
                      The duty to be paid on importation of motor vehicles into Namibia is based on the value of the
                      vehicle
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      Before you can register your car in Namibia, you have to pay
                      Customs duties (29%, plus &% ad valorem for higher valued vehicles), and VAT (15%).
                    </p>

                    <div>
                      <br/>
                      <br/>
                      <h4 className="page-header-content" style={{ color: '#b60811', textAlign: 'center'}}>FAQs</h4>
                      <br/>
                      <div style={{ color: '#424242'}}>
                        <p style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>How much does it cost to ship a
                          car from UK to Windhoek, Namibia?</p>
                        <div style={{fontSize: '15px', color: '#424242'}}>Shipping charge is calculated based on the
                          size of the vehicle. Use the table below for reference.
                        </div>
                        <div className="container" style={{fontSize: '15px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Vehicle Type</th>
                              <th>Walvis Bay</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Saloon
                              </td>
                              <td>from $900</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                SUV, 4x4 (Pick Up)
                              </td>
                              <td>from $1100.00</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Van
                              </td>
                              <td>from $950</td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <p style={{fontSize: '16px', fontWeight: 700}}>
                          How much are vehicle import duties and taxes in Namibia?
                        </p>
                        <div style={{fontSize: '15px'}}>
                          The following should be regarded as a guide only, as the final assessment is given by the
                          Namibia Ministry of Finance at the time of clearance at the border.
                          <br/>
                          <span style={{fontWeight: 700}}>Customs Duties of 29% </span>
                          is payable on the Customs Value of the imported vehicle. Customs Value is derived from
                          FOB value. An additional 7% ad valorem is payable for higher valued vehicles
                          <br/>
                          <span style={{fontWeight: 700}}>VAT of 15% </span>
                          is payable on customs value of the import vehicle.
                        </div>

                      </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px', textAlign: 'center'}}>
                      Quick Import enquiry request </p>
                    <div>
                      <ImportRequestForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

}

export default ImportCarToNamibia;
