import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/zambia-shipping-map.png'
import CountryImage from './../../assets/single-elements/blog-images/zambia-flag-round-small.jpg'
import pdf from './../../shared/ZM_Vehicle_Docs.pdf'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToZambia extends Component {

  render() {
    const title = "Importation of vehicles to Zambia from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Zambia"
    const pubDate = "9 Feb - 4 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Importation Process, UK to Zambia</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a vehicle from the UK to Zambia" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <BlogBlueprint
                    title={title}
                    bImage={CountryImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </BlogBlueprint>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                   We supply brand new and pre-owned vehicles from the UK to our clients based in Zambia.
                  </p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                    To order a vehicle, browse available stock on the site <a className="a-links" href="/">using the search feature here </a>
                    OR submit an import enquiry request below for a quote on any vehicle available in the UK market.
                    Cars are either shipped in containers or using the ‘roll on roll off’ (RORO) option. CHICARGOS will collate and prepare
                    all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                    Marine Insurance Documents, Receipts and any other documents required for shipping, and process these on your behalf
                  </p>

                  <p className="how-to-steps" style={{fontSize: '16px'}}>Port of delivery from the UK</p>
                  <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                    <p>
                      Zambia is landlocked thus CHICARGOS offers a shipped and insured service to the following ports:
                    </p>
                    <p>
                      <span style={{fontWeight: 700}}>Dar es Salaam: </span>
                      Customer is responsible for picking up the vehicle at the port
                    </p>
                    <p>
                      <span style={{fontWeight: 700}}>Walvis Bay: </span>
                      Customer is responsible for picking up the vehicle at the port
                    </p>
                    <div style={{
                            marginTop: "25px",
                            height: '350px',
                            backgroundImage: `url(${bImage})`
                          }} className="help-department-heading"/>
                  </div>
                  <br/>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                    Importing a vehicle from the UK takes approximately 30 days;
                    we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                    CHICARGOS will ship the vehicle and send relevant documentation via DHL.
                  </p>
                  <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                    Documentation required at importation by the Zambia customs authority for clearance purposes:
                  </p>
                  <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                    <ul>
                      <li>
                        V5 Logbook
                      </li>
                      <li>
                         Bill of Lading
                      </li>
                      <li>
                         Invoice or letter of sale indicating the price paid
                      </li>
                      <li>
                        Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                        These may include certificate of registration and police clearance certificate.
                      </li>
                      <li>
                        Spare key (if available)
                      </li>
                    </ul>
                  </div>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on duty calculation </p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                    The following should be regarded as a guide only, as the final assessment is given by the
                    Zambian Revenue Authority (ZRA) at the time of clearance at the border.
                  </p>
                  <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      <a className="a-links" href={pdf}>Click here to view the duty rates leaflet</a>
                  </p>
                  <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px'}}>Quick Import enquiry request </p>
                  <div>
                    <ImportRequestForm />
                  </div>
                </div>
          </div>
        </div>
         </div>
        </div>
        </section>
      </div>
    )
  }

}

export default ImportCarToZambia;
