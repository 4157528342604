import React, {Component} from "react";
import SearchResGrid from "./searchResGrid";
import {searchAction, vehicleAction} from "../../store/actions";
import { Loading } from './../LoadingComponent';
import {connect} from "react-redux";
import {compose} from "redux";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router-dom";

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      res: [],
      keyword: "",
      currentResults: [],
      allResults: []
    };
  }

  componentDidMount() {
    const query = this.props.match.params.stage
    const { getAllProducts } = this.props
    getAllProducts("all")
    this.setState({keyword: query})
    const { getAllProductsSuccess } = this.props


    // const categoryArray = [
    //   "drinks", "snacks", "shop", "beer", "cider", "spirits", "non-alcoholic", "wine", "tobacco", "offers"
    // ]
    // this.setState({keyword: query, allResults: getAllProductsSuccess})
    // if (query === "drinks") {
    //   this.props.filterByClass({proClass: 1, defaultList: getAllProductsSuccess})
    // }
    // if (query === "snacks") {
    //   this.props.filterByClass({proClass: 2, defaultList: getAllProductsSuccess})
    // }
    // if (["beer", "cider", "spirits", "non-alcoholic", "wine", "tobacco", "offers"].includes(query)) {
    //   this.props.filterByCat({cat: query, defaultList: getAllProductsSuccess})
    // }
    // if (query === "shop") {
    //   this.setState({currentResults: getAllProductsSuccess})
    // }
    // else if (!(categoryArray.includes(query))) {
    //   this.props.filterByValue({value: query, defaultList: getAllProductsSuccess})
    // }
  }

  componentDidUpdate(prevProps) {
    let { getAllProductsSuccess, getAllProductsError, getAllProductsLoader, classSearch, catSearch } = this.props
    if ( getAllProductsSuccess && prevProps.getAllProductsSuccess !== getAllProductsSuccess ) {
      this.setState({allResults: getAllProductsSuccess, currentResults: getAllProductsSuccess})
      this.props.filterByCat({cat: this.props.match.params.stage, defaultList: getAllProductsSuccess})
    }
    // if (this.state.keyword !== this.props.match.params.q) {
    //   this.setState({ keyword: this.props.match.params.q });
    //   window.location.reload(false)
    // }
    // if (this.props.searchReducer.catSearch &&
    //   this.props.searchReducer.catSearch !== prevProps.searchReducer.catSearch) {
    //   this.setState({ currentResults: this.props.searchReducer.catSearch })
    // }
    if (catSearch && prevProps.catSearch !== catSearch ) {
      this.setState({ currentResults: catSearch })
    }
    //
    // if (this.props.searchReducer.filterSearch &&
    //     this.props.searchReducer.filterSearch !== prevProps.searchReducer.filterSearch){
    //   this.setState({currentResults: this.props.searchReducer.filterSearch})
    // }
  }

  render() {
    const {keyword} = this.state
    const { getAllProductsLoader } = false
    let searchResults = this.state.currentResults;

    return (
      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row" style={{background: 'white', margin: '32px 0px'}}>
            <div className="container" style={{paddingBottom: '64px'}}>
              {searchResults.length === 0 ? null:
                <div
                  style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "80%",
                  padding: "16px 8px 16px 16px",
                  borderBottom: "1px solid #f3f3f3",
                  }}>
                  Results match for <span style={{color: "#ff4800"}}>"{keyword}"</span>
                </div>
              }
              {getAllProductsLoader
                ?
                <Loading/>
                :
                <div className="row">
                  { searchResults.length === 0 ?
                    <div
                      style={{
                        minHeight: "400px",
                        fontWeight: "bold",
                        paddingLeft: "10px",
                        paddingTop: "32px"
                      }}>
                      Oops, no exact matches found for <span style={{color: "#ff4800"}}>"{keyword}"</span> </div>
                    :
                    searchResults.map((data, index ) => {
                      if (data) {
                        return (
                          <SearchResGrid d={data} i={index}>
                          </SearchResGrid>
                        )
                      }
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


// const mapStateToProps = state => ({
//   vehicleReducer: state.vehicleReducer,
//   searchReducer: state.searchReducer,
// });
const mapStateToProps = state => {
  const {
    vehicleReducer: {
      getAllProductsLoader,
      getAllProductsSuccess,
      getAllProductsError
    },
    searchReducer: {
      filterSearch,
      filterSearchError,
      filterSearchLoader,
      catSearch,
      classSearch
    }
  } = state;
  return {
    getAllProductsLoader,
    getAllProductsSuccess,
    getAllProductsError,
    filterSearch,
    filterSearchError,
    filterSearchLoader,
    catSearch,
    classSearch
  };
};


const mapDispatchToProps = dispatch => {
  return {
    getAllProducts: payload =>
      dispatch(vehicleAction.getAllProducts(payload)),
    filterByCat: payload =>
      dispatch(searchAction.filterByCat(payload)),
    filterByClass: payload =>
      dispatch(searchAction.filterByClass(payload)),
    filterByValue: payload =>
      dispatch(searchAction.filterByValue(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
  withCookies,
  withRouter
)(SearchList));



