import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText, Col
} from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import { veh_data } from '../shared/vehicle_make_and_models';
import './ImgAndForm.css';

class ImgAndForm extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value4: {
        min: 100,
        max: 5000
      },
      fromPrice: 0,
      toPrice: 75000,
      makeSelected: null,
      modelSelected: 'any',
      typeSelected: 'cars',
      message: '',
      carPrices: [
        0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  toggle(typeSelected) {
    if (this.state.typeSelected !== typeSelected) {
      this.setState({ typeSelected });
    }
  }

  error(message) {
    this.setState({ message });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };
  handleMakeChange(event) {
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    this.setState({ modelSelected: event.target.value });
  }

  handleFromPriceChange = event => {
    this.setState({ fromPrice: event.target.value });
  };
  handleToPriceChange = event => {
    this.setState({ toPrice: event.target.value });
  };

  async handleSearch(event) {
    event.preventDefault();
    let {
      makeSelected,
      fromPrice,
      toPrice
    } = this.state;
    if (makeSelected === null) {
      this.error('Make is missing');
      this.searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return;
    }
    const query = `partner-one-search/${makeSelected}/${fromPrice}/${toPrice}/1/b`;
    this.props.onSearch(query);
  }

  keyToOption(key) {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  }

  render() {
    const vehicles = veh_data.reduce(
      (acc, veh, i) => {
        let make = Object.keys(veh)[0];
        let vehModels = veh[make];
        return {
          makes: [
            ...acc.makes,
            <option key={make + i} value={make}>
              {this.keyToOption(make)}
            </option>
          ],
          models: {
            ...acc.models,
            [make]: [
              <option key={make + i + 0} value="any">
                Any Model
              </option>,
              vehModels.map((model, i) => {
                return (
                  <option key={make + model + i} value={model}>
                    {this.keyToOption(model)}
                  </option>
                );
              })
            ]
          }
        };
      },
      { makes: [], models: [] }
    );

    const selectMinValues = this.state.carPrices
      .filter((val) => {
        return val < this.state.toPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.carPrices
      .filter((val) => {
        return val > this.state.fromPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });


    const selectedModels =
      this.state.makeSelected && this.state.makeSelected.length ? (
        vehicles.models[this.state.makeSelected]
      ) : (
        <option selected disabled>
          {/*Model (select make first)*/}
        </option>
      );
    return (
      <div
        className="col-lg-12"
        style={{ padding: '0px' }}
      >
        <header className="headerbg d-flex">
          <div
            className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <div className="container my-auto">
              <div className="bg-text">
                <h5 className="img-form-header" style={{fontSize: '100%', fontWeight: 700}}>Learn & Revise</h5>
                <div
                  className="container"
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 10px 20px 10px',
                    borderRadius: '0px'
                  }}
                >
                  <Col sm={12} className="">
                    <ListGroup style={{margin: "8px", cursor:'pointer'}}>
                      <ListGroupItem
                        tag="a"
                        href="/search/primary"
                        style={{backgroundColor: '#009150', textAlign: 'center'}}>
                        <ListGroupItemHeading style={{fontSize: "16px", fontWeight: 900, color: 'white'}}>Primary</ListGroupItemHeading>
                        <ListGroupItemText style={{fontSize: "14px", fontWeight: 700, color: 'white'}}>
                          Year 1 - 6
                        </ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                    <ListGroup style={{margin: "8px", cursor:'pointer'}}>
                      <ListGroupItem
                        tag="a"
                        href="/search/secondary"
                        style={{backgroundColor: '#009150', textAlign: 'center'}}>
                        <ListGroupItemHeading style={{fontSize: "16px", fontWeight: 900, color: 'white'}}>Secondary</ListGroupItemHeading>
                        <ListGroupItemText style={{fontSize: "14px", fontWeight: 700, color: 'white'}}>
                          Year 7 - 11
                        </ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                    <ListGroup
                      tag="a"
                      href="/search/tertiary"
                      style={{margin: "8px", cursor:'pointer'}}>
                      <ListGroupItem style={{backgroundColor: '#009150', textAlign: 'center'}}>
                        <ListGroupItemHeading style={{fontSize: "16px", fontWeight: 900, color: 'white'}}>Tertiary</ListGroupItemHeading>
                        <ListGroupItemText style={{fontSize: "14px", fontWeight: 700, color: 'white'}}>
                          A Level +
                        </ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner-img col-lg-8 col-md-8">
          </div>
        </header>
        <div className="col-lg-10" />
      </div>
    );
  }
}
// const genSlideStyle = (value) => {
//     return {
//         point: {
//             left: `calc(${value * 20}% - ${5 + 3 * value}px)`,
//         },
//         range: {
//             width: `${value * 20}%`,
//         },
//     };
// };

export default ImgAndForm;
