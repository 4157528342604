import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/kenya-shipping-map.jpg'
import CountryImage from './../../assets/single-elements/blog-images/kenya-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToKenya extends Component {

  render() {
    const title = "Importation of used cars to Kenya from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Kenya"
    const pubDate = "24 June - 5 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Shipping a car from UK to Kenya</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a used car from the UK to Kenya" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page blogs-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" >
                    <BlogBlueprint
                      title={title}
                      bImage={CountryImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      We are a supplier of brand new and pre-owned vehicles from the UK to our clients based in Kenya.
                      <br/>
                      CHICARGOS is rated as the #1 vehicle exporter to the African country.
                      <br/>
                      You will find a fantastic selection of used cars and vans listed on our website
                    </p>
                    <br/>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      To order a vehicle, <a className="a-links" href="/"> browse the available stock
                      using the search feature here </a>
                      <br/>
                      <span style={{fontWeight: 700, textAlign: 'center'}}>OR</span>
                      <br/>
                      Submit an Import Inquiry Request below for a quote on any vehicle available on the UK market.
                      <br/>
                    </p>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px' }}>
                      Car shipping service to Dar Es Salaam, Kenya from UK</p>

                    <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>

                      <p>
                        There are two methods of shipping a vehicle; RORO (Roll-on Roll-off) and container shipment option.
                        <br/>
                        RORO method is most popular whereas container is arranged upon customer’s request.
                        <br/>
                        <br/>
                        We will collate and prepare all documentation needed including
                        <span style={{fontStyle: 'Italic'}}> Log Books, Invoices,
                        Bills of Lading </span> and any other documents required for shipping, and process these on your behalf
                      </p>
                      <p>
                        The main harbour is in Mombasa thus CHICARGOS offers a shipped and insured
                        service to the following ports:
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Mombasa: </span>
                        Customer is responsible for picking up the vehicle from the port
                      </p>
                      <div style={{
                        marginTop: "25px",
                        height: '350px',
                        backgroundImage: `url(${bImage})`
                      }} className="help-department-heading"/>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Importing a vehicle from the UK takes approximately 21-24 days;
                      shipping vessels set sail from the UK on a weekly basis. <br/> <br/>
                      Once the vehicle is identified and payment is received, we will keep you informed throughout
                      the whole process with walkaround videos and demonstrations.
                      We will ship the vehicle and send relevant documentation via DHL before the vessel arrives.
                    </p>
                    <p className="page-information" style={{paddingTop: '20px', fontSize: '15px'}}>
                      Documentation required at importation by the Kenya Revenue Authority (KRA) for clearance purposes:
                    </p>
                    <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                      <ul>
                        <li>
                          Bill of lading
                        </li>
                        <li>
                          Invoice or letter of sale indicating the price paid
                        </li>
                        <li>
                          Log Book
                        </li>
                        <li>
                          QISJ Inspection Documents
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Kenya </p>
                    <p className="page-information"
                       style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      <span style={{fontWeight: 700}}>The following should be regarded as a guide only, </span>
                      as the final assessment is given by the Kenya Revenue Authorities at the time of clearance
                      at the border.
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '10px'}}>
                      The duty to be paid on importation of motor vehicles into Kenya is based on the value of the
                      vehicle (CIF)
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      Before you can register your car in Kenya, you have to pay
                      Import duties (0 - 25%), VAT (20%) and Execise duty (0 - 10%)
                    </p>

                    <div>
                      <br/>
                      <br/>
                      <h4 className="page-header-content" style={{ color: '#b60811', textAlign: 'center'}}>FAQs</h4>
                      <br/>
                      <div style={{ color: '#424242'}}>
                        <p className="questions" style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>
                          Q. How much does it cost to ship a
                          used car from UK to Nairobi, Kenya?</p>
                        <div className="answers" style={{fontSize: '15px', color: '#424242'}}>
                          Shipping charge is calculated based on the
                          size of the vehicle. Use the table below for reference.
                        </div>
                        <div className="container" style={{fontSize: '15px', paddingTop: '20px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Vehicle Type</th>
                              <th>Mombasa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Saloon
                              </td>
                              <td>from $900</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                SUV, 4x4 (Pick Up)
                              </td>
                              <td>from $1100</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Van
                              </td>
                              <td>from $950</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <p className="questions" style={{fontSize: '16px', fontWeight: 700}}>
                          Q. How much is vehicle Import Excise in Kenya?
                        </p>
                        <div className="answers" style={{fontSize: '15px'}}>
                          The following should be regarded as a guide only, as the final assessment is given by the
                          Kenya Revenue Authority at the time of clearance at the border.
                          <br/>
                          <br/>
                          <span style={{fontWeight: 700}}>Import Duty:</span> Around 25% dutiable value
                          <br/>
                          <span style={{fontWeight: 700}}>Excise Duty:</span>
                          Around 20% of the sum of import duty and customs value
                          <br/>
                          <span style={{fontWeight: 700}}>Value Added Tax:</span>
                          16% of the sum of import duty, customs value and excise duty
                        </div>

                      </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px', textAlign: 'center'}}>
                      Quick Import enquiry request </p>
                    <div>
                      <ImportRequestForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}

export default ImportCarToKenya;
