import BestDeals from './../components/BestDeals';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        vehicleReducer: {
            // getBestDealsSuccess,
            // getBestDealsError,
            // getBestDealsLoader,
            getPromotionalApiDataSuccess,
            getPromotionalApiDataError,
            getPromotionalApiDataLoader,
        }
    } = state;
    return {
        // getBestDealsSuccess,
        // getBestDealsError,
        // getBestDealsLoader,
        getPromotionalApiDataSuccess,
        getPromotionalApiDataError,
        getPromotionalApiDataLoader,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // getBestDeals: _ =>
        //     dispatch(vehicleAction.getBestDeals()),
        getPromotionalApiData: _ =>
            dispatch(vehicleAction.getPromotionalApiData()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BestDeals);