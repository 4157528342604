import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, withRouter} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css"
import NumberFormat from "react-number-format";
import Typography from '@mui/material/Typography';
import { withCookies } from "react-cookie";
import { compose } from "redux";
import _ from 'lodash';
import {Helmet} from "react-helmet";
import { Event } from "../Tracking/Event"
import ReactHtmlParser from 'react-html-parser';
import {isEmpty} from "lodash";
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from "prop-types";
import Box from '@mui/material/Box'

import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Alert,
  BreadcrumbItem,
  Breadcrumb,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText, ListGroup
} from "reactstrap";

import onImage from "../../assets/images/no-image.jpg";
import {Ports} from '../../shared/port_country';
import RenderContentComponent from "./RenderContentComponent";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        TabNav(event);
      }}
      {...props}
    />
  );
}

function TabNav(event){
  event.preventDefault()
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



class ProductDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.buyNowRef = React.createRef();
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleNavToLecture = this.handleNavToLecture.bind(this)

    const { cookies } = props;
    this.state = {
      selectedIndex: 0,
      selectedValue: 'RORO',
      selectedPort: [],
      countries: [],
      ports: [],
      insurance: true,
      currSlide: 0,
      shippingCharge: 0,
      shippingCode: [],
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      vehicleID: "",
      message: "",
      selectedAttr: "",
      destinationCountry: "Kenya",
      destinationPort: "Mombasa",
      url: "",
      shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel",
      optionsData: [],
      hasOptions: false,
      selectedOption: {},
      addToBagNotification: false,
      count: 1,
      value: 0,
      lectureId: null,
      courseId: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }

  }

  componentDidUpdate(prevProps) {
    let { getVehicleDetail, addProductToBasketSuccess, addProductToBasketError, } = this.props;

    if (addProductToBasketError !== null && prevProps.addProductToBasketError !== addProductToBasketError) {
      if (addProductToBasketError === "AjaxError: ajax error 406") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "You have items awaiting checkout. Please proceed to checkout the outstanding items first or remove them from your basket"})
      } else if(addProductToBasketError === "AjaxError: ajax error 401") {
          this.props.history.push(`/login`);
      } else if(addProductToBasketError === "Item is unavailable") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "This product is out of stock "})
      } else {
        this.setState({message: addProductToBasketError})
      }
    }
    if (this.props.match.params.lectureId && prevProps.match.params.lectureId !== this.props.match.params.lectureId){
      let lectureId = parseInt(this.props.match.params.lectureId)
      this.setState({value: 1, lectureId: lectureId})
    }

    if (prevProps.match.params.lectureId !== this.props.match.params.lectureId){
      if (this.props.match.params.lectureId === undefined){
        this.setState({value: 0})
      }
    }

    if (getVehicleDetail && prevProps.getVehicleDetail !== getVehicleDetail) {
      this.setState({ url: getVehicleDetail.url})
      if (getVehicleDetail.options === undefined) {
        this.setState({hasOptions: false})
      }
      else {
        if (getVehicleDetail.options.length === 0) {
          this.setState({hasOptions: false})
        }
        else {
          this.setState({
            optionsData: getVehicleDetail.options,
            hasOptions: true
          })
        }
      }
    } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
      this.props.history.push(`/my-basket`);
    }
  }

  responsive = {
      0: { items: 2 },
      300: { items: 3 },
      400: { items: 3 },
      500: { items: 4 },
      600: { items: 4 },
      700: { items: 4 },
      800: { items: 4 },
      1024: { items: 4 },
      2048: { items: 5 },
  }

  stagePadding = {
      paddingLeft: 0,
      paddingRight: 40,
  }

  body_type_suv = [
      'convertible','sports', '4x4', 'double cab','pick-up', 'touring', 'pickup', 'two door saloon gt shell', '3 door',
      'suv', 'stationwagon', 'low loader', 'pick up with canopy', 'four wheel drive', 'double cab pickup', 'mpv',
      'light 4x4 utility',

  ]

  body_type_sedan = [
      'four wheel drive hatchback', '5 door hatchback', 'sport back', 'hatch','2 door saloon', 'grand coupe', 'saloon',
      'hatchback', '5 door hatch', 'cabriolet // convertible', '4 door saloon', 'roadster','sedan', 'estate',


  ]


  componentDidMount() {
      let lectureId = this.props.match.params.lectureId
      let courseId = this.props.match.params.id
      let tabValue = 0
      if (lectureId) {
        tabValue = 1
      }
      document.body.scrollTop = 0
      window.scrollTo(0,0);

      const { getVehicle, getCountries, getPromotionalApiData, getShippingMethods } = this.props;
      let id = window.location.pathname.split('/')[2];
      getVehicle(id);
      getCountries();
      getPromotionalApiData();
      getShippingMethods();
      this.setState({vehicleID: id, lectureId: lectureId, value: tabValue, courseId: courseId})
  };

  toggle(event, newValue) {
    let URL = `/details/${this.state.courseId}`
    if (newValue === 0){
      if (this.props.match.params.lectureId === undefined){
        this.setState({value: 0})
      } else {
        this.props.history.push(URL)
      }
    }
    else {
      this.setState({value: newValue})
    }
  }

  handleNavToLecture(lectureId){
    let URL = `/details/${this.state.courseId}/${lectureId}`
    this.props.history.push(URL)
  }

  slideTo = (i) => this.setState({ currSlide: i });

  handleBreadCrumb(type, value) {

  if (type === "cat") {
    if (value === "Men") {
      this.props.history.push("/search/cat-search-men");
    }
    if (value === "Women") {
      this.props.history.push("/search/cat-search-women");
    }
  }
  }

  handleMakeChange(e) {
      this.setState({ [e.target.name]: e.target.value,  });
  }

  handlePorts = (e) => {
    this.setState({
      selectedPort: e.target.value,
      shippingCharge: 700
    }, () => {this.updateShippingCode()})
  }

  updateShippingCode = () => {
      const { getShippingMethodsSuccess } = this.props
      const shippingCodesFromMethods = getShippingMethodsSuccess.map(shippingObject => shippingObject.code)
      let veh_type = 'other'
      if (!(typeof this.state.selectedPort === 'string')) {
          return;
      }
      let selectedPort = this.state.selectedPort.split(" ").join("-").toLowerCase();
      let portMatches = _.filter(shippingCodesFromMethods, function (s) {
          return s.indexOf(selectedPort) !== -1;
      });
      if (this.props.getVehicleDetail.specs.body_type !== null ) {
          if (new RegExp(this.body_type_sedan.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'sedan'
          } else if (new RegExp(this.body_type_suv.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'suv'
          }
      }

      let codeMatch = _.filter(portMatches, function (s) {
          return s.indexOf(veh_type) !== -1;
      });

      this.setState({
          shippingCode: codeMatch,
          message: ""
      }, () => {this.logIt()})
  }

  logIt()  {
  }

  handleCountryChange(e) {
    let Port = Ports.filter(x => x[e.target.value]);
    if(Port.length > 0)
      Port = Port[0][e.target.value]
    this.setState({ countries: e.target.value, ports : Port || []   });
  }

  handleRadios(val) {
    if (val === 'RORO'){
      this.setState({
        selectedIndex: 0, selectedValue: 'RORO', shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel" }, () => {this.logIt()})
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 700})
      }
    }
    else {
      this.setState({ selectedIndex: 1, selectedValue: 'Container', shippingOptionDetail: "Container: Your vehicle will be shipped in a container" })
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 1200})
        }
      }
  }

  handleOptionsChange(event) {
    event.preventDefault();
    let selectedValue = event.target.options[event.target.selectedIndex].text
    let selectedOpt = this.state.optionsData.filter(function(item) {
      return item.name === selectedValue
    })
    this.setState({selectedOption: selectedOpt[0], message: ""})
  }

  handleBuyNow (prodId, prodPrice, prodTitle, mainImage) {
    Event("PRODUCT", "Product added to cart", "PRODUCT_DETAIL_PAGE")
    const { count } = this.state
    const payload = {
      prodId: prodId,
      prodTitle: prodTitle,
      prodPrice: prodPrice,
      numItems: count,
      mainImage: mainImage
    }
    if (this.state.selectedOption) {
      payload["prodOpt"] = this.state.selectedOption
    }
    if (this.props.isLoggedIn) {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartAuthenticated(payload)}>
        Buy Now
      </Button>
    } else {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartUnauthenticated(payload)}>
        Buy Now
      </Button>
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  redirectAuthPage () {
    this.props.history.push("/login")
  }

  search (nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i];
      }
    }
  }

  handleHide= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      addToBagNotification: false
    })
  };

  handleClick = (e) => {
    // Use updater function when new state is derived from old
    const maxItems = 25
    const minItems = 1
    const currentCount = this.state.count

    if (e === "Increase") {
      if (currentCount < maxItems) {
        this.setState(prev => ({ count: prev.count + 1 }));
      } else {
        return
      }
    } else {
      if (currentCount === minItems) {
        return
      }
      this.setState(prev => ({ count: prev.count - 1 }));
    }

  };



  render() {
    const { hasOptions } = this.state;
      const { getPriceSuccess, getVehicleDetailLoader, getVehicleDetail, getPromotionalApiDataSuccess,
        getStockRecordSuccess, addProductToBasketLoader } = this.props;
      const {
        currSlide,
        message,
      } = this.state;
      let prodPrice = null;
    // let cat = getVehicleDetail && getVehicleDetail.categories[0]
      if (getPriceSuccess) {
        prodPrice = getPriceSuccess.incl_tax
      }
      let searched = [];
      let _images = [];
      let descr = [];
      let prodID = null;
      let prodTitle = null;
      let mainImage = null;
      let category = null;

      if(getVehicleDetail) {
          const { id, images, description_clean, title } = getVehicleDetail;
          _images = images;
          descr = description_clean.split('\n');
          prodID = id
          prodTitle = title
          mainImage = "https://www.esicm.org/wp-content/uploads/2017/06/elearning.png"

      }
      category = getVehicleDetail && getVehicleDetail.categories[0]
      if (getPromotionalApiDataSuccess) {
          const { most_searched: { sedan } } = getPromotionalApiDataSuccess;

          // TODO
          // logic to determine upselling category
          searched = [...sedan];
      }

      let availability = 0

      if (getStockRecordSuccess) {
        availability = getStockRecordSuccess.results[0].num_in_stock - getStockRecordSuccess.results[0].num_allocated
      }

      const pageTitle = getVehicleDetail && getVehicleDetail.title
      const fullTitle = "See Tutors: " + pageTitle
      const productClass = getVehicleDetail && getVehicleDetail.product_class
      const classValue = productClass === 'cars' ? '1' : '2'

      return (
        <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content="UK Premier exporter" />
          </Helmet>
          <div className="inner-container" id="contactContainer">
            <div className="row">

              {getVehicleDetailLoader ? (
                <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                  <Loading />
                </div>
              ) : (
                  <div className="container"  style={{paddingTop: '30px'}}>
                    <Breadcrumb style={{marginBottom: "10px"}}>
                      <BreadcrumbItem>
                        <Link to="/" className="listedLink">
                          Home
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link
                            to="#"
                            className="listedLink"
                            onClick={() => this.handleBreadCrumb('cat', category )}
                        >
                          { category }
                          </Link>{" "}
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        {/*<Link to="#">*/}
                        { getVehicleDetail && getVehicleDetail.title }
                        {/*</Link>*/}
                      </BreadcrumbItem>
                    </Breadcrumb>
                      <div>
                      <Row className="car-detail-layout">
                        <Col sm={12} md={5} lg={5} className="car-preview-gallery">
                          <div  style={{ position: 'relative' }}>
                            <Card>
                              <CardImg
                                style={{objectFit:"cover"}}
                                width="100%" src={mainImage} alt="Card image cap" />
                            </Card>
                          </div>
                        </Col>
                        <Col className="item-overview-detail" sm={12} md={7} lg={7}>
                          <p style={{ textAlign: 'left',
                            fontWeight: 'bolder',
                            fontSize: '24px',
                            color: 'black',
                            marginBottom: '0.75rem',
                            paddingLeft: '5px'}}>
                            { getVehicleDetail && getVehicleDetail.title }
                          </p>
                          <div id="wrapper" style={{ paddingBottom: '5px', paddingLeft: '10px' }}>
                            <p style={{ textAlign: 'left',
                              fontWeight: 500, fontSize: '14px'}}>
                              { getVehicleDetail && getVehicleDetail.source_cat_name }
                            </p>
                            {/*<p className="detailPagePrice">*/}
                            {/*  <NumberFormat*/}
                            {/*    value={parseFloat(prodPrice).toFixed(2)}*/}
                            {/*    displayType={'text'}*/}
                            {/*    thousandSeparator={true}*/}
                            {/*    prefix={'£'}*/}
                            {/*    // suffix={'.00'}*/}
                            {/*  />*/}
                            {/*</p>*/}
                            {/*<p className="stockdata">*/}
                            {/*  Availability:*/}
                            {/*  <span className={"stock-" + (availability < 1 ? 'unavailable': 'available')}>*/}
                            {/*    { availability < 1 ? ' OUT OF STOCK': ' IN STOCK' }*/}
                            {/*  </span>*/}
                            {/*</p>*/}
                            </div>

                            { addProductToBasketLoader ? (
                              <div className="offset-1 offset-lg-1"
                                   style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                                <Loading />
                              </div>
                            ) : (
                            <div className='userOrderPreferences'>
                              <div>
                              <FormGroup className="alert-set-destination">
                                <div ref={this.buyNowRef}>
                                {message && <Alert color="danger">
                                  {message}
                                </Alert>}
                                </div>
                              </FormGroup>
                                {hasOptions ?
                              <FormGroup className="dropdownsContainer">
                                <Input
                                    className='dropDowns'
                                    style={{ marginRight: '20px' }}
                                    onChange={e => this.handleOptionsChange(e)}
                                    type="select"
                                    name="country"
                                    id="q">
                                  <option selected disabled>
                                      Select size
                                  </option>
                                    {
                                        getVehicleDetail && getVehicleDetail.options.map((opt, ind) =>
                                            <option key={ind.toString()} value={opt.name}>
                                                { opt.name }
                                            </option>
                                        )
                                    }
                                  </Input>
                              </FormGroup> : null}
                              </div>

                            </div>
                              )}

                        </Col>
                      </Row>

                      <div style={{ marginTop: 50, minHeight: '300px', marginBottom: '64px' }}>
                        <Row>
                          <Col sm={12} md={12} lg={12}>
                            <TabContent activeTab style={{ paddingTop: '0px'}}>
                              <Tabs
                                value={this.state.value}
                                onChange={this.toggle}
                                aria-label="nav tabs example"
                                indicatorColor="primary"
                                textColor="inherit"
                                style={{borderBottom: "1px solid #dee2e6"}}
                              >
                                <LinkTab style={{ fontWeight: 800, marginRight: '24px'}} label="OVERVIEW" href="#" />
                                <LinkTab style={{ fontWeight: 800}} label="COURSE DETAIL" href="trash" />
                              </Tabs>
                              <TabPanel value={this.state.value} index={0}>
                                <Row>
                                  <Col style={{ paddingBottom: '20px' }} sm={12} md={4}>
                                    <Typography
                                      style={{
                                        fontWeight: 800,
                                        marginTop: '40px',
                                        color: 'black'}}
                                      className='overview-headings'
                                    >
                                      Course description
                                    </Typography>
                                    <div className='descriptionElement'>
                                      <div style={{whiteSpace: "pre-line"}} className='descriptionContainer'>
                                        {getVehicleDetail && getVehicleDetail.description_clean}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm={12} md={8}>
                                    <Typography
                                      style={{
                                        fontWeight: 800,
                                        marginLeft: '15px',
                                        marginTop: '40px',
                                        color: 'black'
                                      }}
                                      className='overview-headings'
                                    >
                                      Course content
                                    </Typography>
                                    <div>
                                      <ListGroup style={{margin: "15px"}}>
                                        {getVehicleDetail && getVehicleDetail.product_content.map((lecture) =>
                                          <ListGroupItem
                                            style={{cursor:'pointer'}}
                                            onClick={() => this.handleNavToLecture(lecture.id)}>
                                            <ListGroupItemHeading className="lecture-list-headings">
                                              {lecture.title}
                                            </ListGroupItemHeading>
                                            <ListGroupItemText style={{marginBottom: '2px'}}>
                                              By, {lecture.author}
                                            </ListGroupItemText>
                                          </ListGroupItem>
                                        )}
                                      </ListGroup>
                                    </div>
                                  </Col>
                                </Row>
                              </TabPanel>
                              <TabPanel value={this.state.value} index={1}>
                                <RenderContentComponent
                                  contentObj={getVehicleDetail && getVehicleDetail.product_content}
                                  lectureToRender={this.state.lectureId}
                                />
                              </TabPanel>
                            </TabContent>
                        </Col>
                      </Row>
                      </div>
                      </div>
                    <Snackbar
                      autoHideDuration={4000}
                      open={this.state.addToBagNotification}
                      onClose={this.handleHide}
                      message="Item Added"
                      key={"bottom center"}
                    />
                  </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  export default compose(
      withCookies,
      withRouter
  )(ProductDetailsComponent);
