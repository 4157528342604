import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/botswana-shipping-map.png'
import CountryImage from './../../assets/single-elements/blog-images/botswana-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToBotswana extends Component {

  render() {
    const title = "Importation of vehicles to Botswana from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Botswana"
    const pubDate = "5 May - 6 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Import a car from UK to Botswana</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a vehicle from the UK to Botswana" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <BlogBlueprint
                    title={title}
                    bImage={CountryImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </BlogBlueprint>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                   We supply brand new and pre-owned vehicles from the UK to our clients based in Botswana.
                   CHICARGOS assists clients shipping goods from UK to Botswana.
                  </p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                    To order a vehicle, browse available stock on the site <a className="a-links" href="/">using the search feature here </a>
                    OR submit an import enquiry request below for a quote on any vehicle available in the UK market.
                    Cars are either shipped in containers or using the ‘roll on roll off’ (RORO) option. CHICARGOS will collate and prepare
                    all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                    Marine Insurance Documents, Receipts and any other documents required for shipping, and process these on your behalf
                  </p>

                  <p className="how-to-steps" style={{fontSize: '16px'}}>Car shipping service to Gaborone, Botswana from UK</p>
                  <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                    <p>
                      Botswana is landlocked thus CHICARGOS offers a shipped and insured service to the following ports:
                    </p>
                    <p>
                      <span style={{fontWeight: 700}}>Durban: </span>
                      Customer is responsible for picking up the vehicle at the port
                    </p>
                    <p>
                      <span style={{fontWeight: 700}}>Walvis Bay: </span>
                      Customer is responsible for picking up the vehicle at the port
                    </p>
                    <div style={{
                            marginTop: "25px",
                            height: '350px',
                            backgroundImage: `url(${bImage})`
                          }} className="help-department-heading"/>
                  </div>
                  <br/>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                    Importing a vehicle from the UK takes approximately 24 days;
                    we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                    CHICARGOS will ship the vehicle and send relevant documentation via DHL.
                  </p>
                  <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                    Documentation required at importation by the Botswana customs authority (BURS) for clearance purposes:
                  </p>
                  <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                    <ul>
                      <li>
                        V5 Logbook
                      </li>
                      <li>
                         Bill of Lading
                      </li>
                      <li>
                         Invoice or letter of sale indicating the price paid
                      </li>
                      <li>
                        Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                        These may include certificate of registration and police clearance certificate.
                      </li>
                      <li>
                        Spare key (if available)
                      </li>
                    </ul>
                  </div>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Botswana </p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                    The following should be regarded as a guide only, as the final assessment is given by the
                    Botswana customs authority (BURS) at the time of clearance at the border.
                  </p>
                  <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      {/*<a className="a-links" href={pdf}>Click here to view the duty rates leaflet</a>*/}
                      Before you can register your car in Botswana, you have to pay customs duty and VAT.
                    Customs duty is calculated at 27% of the value of the vehicle.
                  </p>

                  <div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>FAQs</p>
                    <div style={{ color: '#424242'}}>
                    <p style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>How much does it cost to ship a car from UK to Botswana, Gaborone?</p>
                    <br/>
                      <div className="container">
                      <table className="table table-striped">
                        <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                        <tr>
                          <th>Vehicle Type</th>
                          <th>Walvis Bay</th>
                          <th>Durban</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                            Saloon
                          </td>
                          <td>from $800</td>
                          <td>from $900</td>
                        </tr>
                        <tr>
                          <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                            SUV, 4x4 (Pick Up)
                          </td>
                          <td>from $1100.00</td>
                          <td>from $1100.00</td>
                        </tr>
                        <tr>
                          <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                            Van
                          </td>
                          <td>from $900</td>
                          <td>from $950</td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                    <br/>
                    <p style={{fontSize: '16px', fontWeight: 700}}>How much are vehicle import duties and taxes in Botswana?</p>
                    <div style={{fontSize: '15px'}}>
                      The following should be regarded as a guide only, as the final assessment is given by the Botswana Unified Revenue Services(BURS)
                      at the time of clearance at the border.
                      <p style={{marginBottom: '0px', fontWeight: 700}}> Customs duty payable is 27%</p>
                      <p style={{fontWeight: 700}}>VAT Tax payable is 12% </p>
                    </div>

                    </div>
                  </div>
                  <br/>
                  <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px'}}>Quick Import enquiry request </p>
                  <div>
                    <ImportRequestForm />
                  </div>

                </div>
          </div>
        </div>
         </div>
        </div>
        </section>
      </div>
    )
  }

}

export default ImportCarToBotswana;
