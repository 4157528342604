import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - About Us</title>
          <link rel="canonical" href="https://www.exukonline.com/about-us" />
          <meta name="description"
                content="UK Premier Import Service - A Global e-commerce company" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content">Who we are</h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          <p className="how-to-steps" style={{fontSize: '14px'}}>About Us</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}> Virtual assistants package.
                              A comprehensive package where we help businesses and entrepreneurs with tasks such as researching,
                              email management, customer services, basic graphic designs and layout, proofreading,
                              bookkeeping, lead generation, social media, and content management.
                              Our services are quite dynamic and involve strategizing and key decision making and
                              most times, executive assistance.  This service varies with clients’ needs as well as
                              their business structure.  We are as passionate about your business as well as dedicated
                              to ensuring you minimize costs and maximize resources. </p>
                            <p className="page-information" style={{fontSize: '14px', display: 'None'}}> We are also a highly trusted provider of electronics and auto parts in the country.
                              Our highly professional and expert team helps you to procure a wide variety of
                              automobile parts at highly affordable rates.</p>

                          <p className="how-to-steps" style={{fontSize: '14px',display: 'None'}}>Our Approach</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px', display: 'None'}}>  At CHICARGOS, we are committed to providing our customers with best quality,
                              prompt and efficient services. We export a range of vehicles to match their needs and
                              facilitate their exporting requirements. All our vehicles sourced as well as procured
                              by our company are of the highest quality and make. They are designed to last long and
                              are fully approved by experienced engineers.</p>
                          <p className="how-to-steps" style={{fontSize: '14px', display: 'None'}}>Why Us?</p>
                          <p className="info-graphics-heading" style={{fontSize: '14px'}} > Connect with us to discuss your requirements!</p>
                          <p className="info-graphics-heading" style={{fontSize: '14px', color: '#980000'}} > Call us on +44 7438 767532</p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
