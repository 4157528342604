import React, {useEffect, useState, useRef} from "react";
import ReactHtmlParser from 'react-html-parser';
import Typography from '@mui/material/Typography';
import {Editor} from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import {convertToRaw, convertFromRaw} from 'draft-js';
import LectureViewComponent from "./LectureViewComponent";

const RenderContentComponent = ({contentObj, lectureToRender}) => {

  const [content, setContent] = useState([])
  const activeSlideRef = useRef(null);

  useEffect(() => {
    if (contentObj){
      contentObj.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (key === 'content') {
            try {
              obj[key] = JSON.parse(obj[key])
            } catch (e) {
              return
            }
          }
          console.log("key : " + key + " - value : " + obj[key]);
        });
      });
      if(lectureToRender) {
        let ids = [parseInt(lectureToRender)]
        contentObj = contentObj.filter(i => ids.includes(i.id))
      }
      setContent([...contentObj])
    }
  }, []);

  return (
    <div>
      {content.map((obj) => (
        <LectureViewComponent
          o={obj}
        />
      ))}
    </div>)
}

export default RenderContentComponent