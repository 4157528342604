import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function LectureFormDialog(
  {handleFormChange, title, author, onFormSubmit, handleClickOpen, handleClose, open}) {

  return (
    <div>
      <Button
        disableElevation
        variant="contained"
        onClick={handleClickOpen}
        className="mui-buttons"
      >
        Create a lecture
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        // fullWidth={'md'}
      >
        <DialogTitle>Create a new lecture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter details below
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            fullWidth
            variant="standard"
            value={title}
            onChange={handleFormChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="author"
            label="Author"
            fullWidth
            variant="standard"
            value={author}
            onChange={handleFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disableElevation variant="contained" onClick={handleClose}>Cancel</Button>
          <Button disableElevation variant="contained" onClick={onFormSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}