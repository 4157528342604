import React from 'react';
import Alert from "@mui/material/Alert";

const StudioHeader = ({title, info}) => {
  return (
    <div style={{padding: "24px 0px"}}>
      <div>
        <h4 className="page-header-content">{title}</h4>
      </div>
      <div style={{paddingBottom: "16px"}}>
        <Alert severity="info">
          {info}
        </Alert>
      </div>
    </div>
  )
}

export default StudioHeader