import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/buy-online.jpeg'
import {Helmet} from "react-helmet";



class ImportVehicleFromAfrica extends Component {

  render() {
    const title = "Buying car online "
    const bImage = CoverImage
    const subHeading = "Import a car from the UK online"
    const pubDate = "12 Dec - 7 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Importation Process, UK to Africa</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when buying a vehicle online
                from the United Kingdom to Africa." />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <BlogBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </BlogBlueprint>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                   A Guide on the things to look out for when buying a vehicle online from the United Kingdom to any destination in Africa.
                  </p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                    These days, with the advent of technology and the rise of e-commerce, a lot of things are changing.
                    Gone are the days where you needed to walk into a place to get a product or a service,
                    now you can get and buy virtually almost anything online.
                    The selling and buying of cars too have not been left our in this trend.
                  </p>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>Due diligence</p>
                  <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                  <p>
                  Buying a car online is not a difficult process once you are aware of what to look out for.
                  There are many things to consider when buying a car and one has to pay more attention to details
                  especially when importing the car from the UK to any destination in Africa.
                  Buying a used car means that there has to be more attention to details because you do not want to
                  venture into it and regret it later on. Below are some of the things one needs to be aware of when
                  buying a used car online:
                  </p>
                    <p  style={{textAlign: 'left'}}>
                      <ul>
                        <li style={{paddingBottom: '5px'}}>
                          Have a budget! Knowing the amount of money, you are willing to spend on
                          the car including the importation cost and all is very important as this will help you work
                          within your budget
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Check the car you want to buy. Ensure that you do due diligence of checking the car you
                          want to buy. You can do this by comparing the car on other listed sites.
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          You will also need to find out if your destination requires you to have an import permit in
                          place such as South Africa, Australia or Jamaica for example.
                          Some import permits can take anything from 1 week up to 120 days to be issued, so finding
                          this information out in plenty of time is the key to a hitch free used car import.
                        </li>
                      </ul>

                    </p>
                  <p>
                  At CHICARGOS, we ensure that we provide our customers with information they need for any form
                    of car import from the UK. We are a well grounded British Car Export specialist and we make sure that
                    you have all the necessary information before you buy and import the car to any destination within Africa.
                    We handle all the necessary paperwork required for shipping and once the vehicle is received we work hand in hand with
                    reputable clearing agents across Africa. Our licensed agents will assist you to clear your vehicle hassle free.
                  </p>
                  <p>
                    If you have any questions regarding the importation regulations in your country click
                    <a className="a-links" href="/local-services">
                      {" here "}
                    </a>
                      to contact our agents, <br/>
                    there are always on stand by to keep you up to date with the latest government regulations and changing compliance laws
                  </p>
                </p>
         </div>
          </div>
        </div>
         </div>
        </div>
      </section>
      </div>
    )
  }

}

export default ImportVehicleFromAfrica;
