import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem, Card, CardImg, CardTitle,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText, Row, Table
} from 'reactstrap';
import moment from 'moment';
import {Loading} from "../LoadingComponent";
import routeTo from "./../helpers/RouteTo"
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';

import getTrainingValues from "./../helpers/GenerateTrainingProgress"




class VendorDashboardComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      isAgent: false,
      discountCode: "",
      discountLevel: "",
      discountCodeUsage: {},
      mobileNumber: "",
      value: "",
      error: false,
      message: "",
      multiView: 0,
      openAgentAccount: false,
      activeStep: 1,
      isChecked: false,
      code: "",
      redeemablePoints: 0,
      referrerEmail: "",
      trainingProgress: {},
      vendorProducts: []
    };

  }

  componentDidMount() {
    const { history, location, isLoggedIn } = this.props;
    if (!isLoggedIn) {
      history.push("/login", location);
    }

    const token = localStorage.getItem('token')

    let headers = { Authorization: `token ${token}` };

    this.props.getUserProfile && this.props.getUserProfile(headers);
    this.props.getUserOrders && this.props.getUserOrders(headers);
    this.props.getAgentProfile && this.props.getAgentProfile(headers);
    this.props.getVendorProducts && this.props.getVendorProducts(headers)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.getAgentProfileSuccess && prevProps.getAgentProfileSuccess !== this.props.getAgentProfileSuccess) {
      const statusCode = this.props.getAgentProfileSuccess.status
      if (statusCode === 204) {
        this.setState({isAgent: false})
      } else if (statusCode === 200) {

        let response = this.props.getAgentProfileSuccess.response
        this.setState({isAgent: true,
          discountCode: "response.code",
          discountLevel: "response.name",
          discountCodeUsage: "response.voucher_application_instances",
          redeemablePoints: "response.voucher_application_instances['redeemable_points']",
          discountCodeStartTime: "response.start_datetime"})
      }
    }

    if (this.props.getVendorProductsSuccess && prevProps.getVendorProductsSuccess !== this.props.getVendorProductsSuccess) {
      this.setState({
        vendorProducts: this.props.getVendorProductsSuccess.data
      })
    }

    if (this.props.getUserProfileSuccess && prevProps.getUserProfileSuccess !== this.props.getUserProfileSuccess) {

      if (this.props.getUserProfileSuccess.agent_training == null) {
        this.setState({
          trainingProgress: {intro: false}
        })
      } else {
        let sample = JSON.parse(this.props.getUserProfileSuccess.agent_training)
        let completeObj = {intro: getTrainingValues(sample, "intro"),
          chapter_1: getTrainingValues(sample, "chapter_1"),
          chapter_2: getTrainingValues(sample, "chapter_2"),
          chapter_3: getTrainingValues(sample, "chapter_3")}
        this.setState({
          trainingProgress: completeObj
        })
      }
    }
  }

  renderAgentInfo(isAgent, discountCode, discountLevel, discountCodeUsage) {
    if ((isAgent) && (discountCodeUsage.usage_details.length > 0))  {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12 search-card">
          <div style={{
            fontSize: "18px",
            fontWeight: "bolder",
            paddingTop: "32px",
            paddingBottom: "32px",
            textAlign: 'center' }}>
            Promo code applied
          </div>
          <hr/>
          { discountCodeUsage.usage_details.map(
            ({ order_number, usage_date, status, is_agent_paid, commission_receipt }, i) => {
              return (
                <Card className="cardSearch" key={i}>
                  <Link to="#">
                    <div className="row">
                      <div className="col col-sm-4 col-md-2 col-lg-3 search-card-img-div">
                        <CardImg
                          src={require("../../assets/single-elements/" + discountLevel + ".png")}
                          className="mx-auto car-img img-responsive card-img-top"
                          id="search-card-imgsdf"
                        />
                      </div>
                      <div className="col col-sm-8 col-md-6 col-lg-6 contentTitles">
                        <div className="topPriceContainer">
                        <span className="price">
                          # {order_number}
                        </span>
                        </div>
                        <div className="container ordersCardProperties"
                             style={{letterSpacing: '0px'}}>
                          Order Status: {status}
                        </div>
                        <CardTitle
                          className="container ordersCardProperties"
                          style={{letterSpacing: '0px'}}>
                          Commission payment: {is_agent_paid ? 'PAID' : 'PENDING'}
                        </CardTitle>
                        <div className="container ordersCardProperties"
                             style={{letterSpacing: '0px'}}>
                          Order date: {usage_date && moment(usage_date).format('MMM. D, YYYY')}
                          <br/>
                          <span
                            className="headings">Points: {commission_receipt ? commission_receipt : 'Pending'} </span>
                        </div>
                      </div>
                      <div
                        className="col col-sm-3 col-md-3 col-lg-3 d-flex flex-column justify-content-between align-items-center price-container">
                      <span className="price">
                        # {order_number}
                      </span>

                      </div>
                    </div>
                  </Link>
                </Card>
              )
            })
          }
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12" style={{textAlign: 'center'}}>
          No course created yet
        </div>
      );
    }
  }

  render() {
    const {history} = this.props
    const {
      isAgent,
      discountCode,
      discountLevel,
      discountCodeUsage,
      discountCodeStartTime,
      trainingProgress,
      redeemablePoints
    } = this.state;
    let points = 0;
    let now = 50;
    let completeProfile = false

    if (Object.values(trainingProgress).includes(false)) {
      points = 0
      now = 50
    } else {
      points = 100
      now = 100
      completeProfile = true
    }

    return <div className="backdrop">
      <div className="container cpanel">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/profile" >Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>Tutor dashboard</BreadcrumbItem>
        </Breadcrumb>
        <div className="row" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <div className="page-header col-12">
            <h4 className="page-header-content">Tutor Dashboard</h4>
            <hr className="fancy-line"/>
          </div>
        </div>
        <div style={{paddingRight: '5px', paddingLeft: '5px'}} className="supporter-program-text">
          <div className="container">
            <div className="row">
              <Col sm={6} className="">
                <ListGroup style={{margin: "15px", cursor:'pointer'}}>
                  <ListGroupItem style={{backgroundColor: '#009150', textAlign: 'center'}}>
                    <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>Courses</ListGroupItemHeading>
                    <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                      {this.state.vendorProducts.length}
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col sm={6} className="">
                <ListGroup style={{margin: "15px", cursor:'pointer'}}>
                  <ListGroupItem style={{backgroundColor: '#ff4800', textAlign: 'center'}}
                                 onClick={() => routeTo(history, "Downline")}
                  >
                    <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>My Students</ListGroupItemHeading>
                    <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                      <img
                        className="link-icons"
                        src={require("../../assets/single-elements/icons8-multicultural-people-52.png")}
                        alt="icons"
                      />
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </div>
            <br/>
          </div>
          <div style={{
            backgroundColor: 'whitesmoke',
            display: 'None',
            marginTop: '0x',
            paddingTop: '20px',
            marginBottom: '40px',
            borderTop: 'solid 1px #e8e8e8'}}>
            <Row style={{paddingBottom: '25px'}}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div style={{fontWeight: 600}}>
                  Training
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <a href="/supporter-training" className="link-text">View Training Resources</a>
                </div>
              </Col>
            </Row>
            <Row style={{paddingBottom: '25px'}}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div style={{fontWeight: 600}}>
                  Promoting
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <a href="/advertising-tools" className="link-text">Download Promotion Media</a>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row style={{ paddingBottom: '8px' }}>
              <Col>
                <h5 className="page-header-content" >
                  Courses
                </h5>
              </Col>
              <Col>
                <div>
                <Button
                  key="Save"
                  variant="contained"
                  disableElevation
                  onClick={() => routeTo(history, "Create")}
                  className="mui-buttons"
                  sx={{
                    marginLeft: 'auto',
                    display: 'block'
                }}
                >
                  Create course
                </Button>
                </div>

              </Col>
            </Row>

          </div>
          <Row style={{ paddingBottom: '40px'}}>
            <Col sm="12">
              <Table className="table-wrapper" bordered striped >
                <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Name</th>
                  <th>Date created</th>
                </tr>
                </thead>
                <tbody>
                {this.state.vendorProducts.map((product) => (
                  <tr>
                    {/*<td>{product.prod_id}</td>*/}
                    <td><a href={`/vendor/add-content/${product.prod_id}`}>{product.prod_id}</a></td>
                    <td><a href={`/vendor/add-content/${product.prod_id}`}>{product.prod_name}</a></td>
                    <td>{product.date_created}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
            {/*{this.renderAgentInfo(isAgent, discountCode, discountLevel, discountCodeUsage)}*/}
          </Row>
        </div>
      </div>
    </div>;
  }
}

export default VendorDashboardComponent;