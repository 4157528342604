import React, { Component } from "react";
import {Alert, Form, FormGroup, Input, Label} from 'reactstrap';
import {Helmet} from "react-helmet";

class AutoParts extends Component{
  constructor(props) {
    super(props);
    this.autoRequestRef = React.createRef()
    this.state = {
        userName: "",
        userEmail: "",
        contactNumber: "",
        userRequest: "",
        error: false,
        message: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;

      this.setState({
          [name]: target.value
      })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { userName, userEmail, contactNumber, userRequest } = this.state

    if (userName === "" || userEmail === "" || contactNumber === "" || userRequest === "") {
      this.autoRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Missing information - Email, Name, Phone number and Request required"})
      return;
    }

    this.sendRequest(process.env.REACT_APP_EMAILJS_TEMPLATEID, this.state.userName, this.state.userEmail,
        process.env.REACT_APP_EMAILJS_RECEIVER, this.state.userRequest, this.state.contactNumber)
    alert('Thank you, ' + userName + ' . A wholesale request was submitted. ' +
      'One of agents will be in touch with you on (' + contactNumber +  ') within 12hrs to discuss further')

  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userName,
          userEmail,
          receiverEmail,
          contactNumber,
          userRequest
        })
        .then(res => {
          this.setState({
              userName: "",
              userEmail: "",
              contactNumber: "",
              userRequest: ""})
        })

        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }


  render(){

    const { message } = this.state;

    return(

      <div>
        <Helmet>
          <meta name="description"
                content="Find best import deals on Auto, Vehicle Parts and much more." />
          <title>CHICARGOS - Auto Parts</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">

              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>AUTO PARTS</h4>
                  <hr />
                  <br />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="auto-parts-department-heading">
                   </div>
                  </div>
                  <br />
                  <br />
                  <p className="page-information" style={{fontSize: '13px'}}>
                  The auto parts department is for our wholesale clients only.
                  </p>
                  <p className="page-information" style={{fontSize: '13px'}}>
                  If you are looking to purchase vehicle spare parts
                  in bulk, please submit your request below and one of our agents will be in touch as soon as possible
                  </p>
                  <br />
                  <div className="col-lg-6 col-sm-12 offset-lg-3" style={{padding: '10px'}}>
                  <Form onSubmit={this.handleSubmit}>
                    <div ref={this.autoRequestRef}>
                      {message &&
                      <Alert color="danger">
                        {message}
                      </Alert>}
                    </div>
                    <FormGroup>
                      <Label for="userEmail" className="form-input">Email</Label>
                      <Input
                        name="userEmail"
                        type="email"
                        value={this.state.userEmail}
                        onChange={this.handleChange}
                        placeholder="Your email address"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userName" className="form-input">Name</Label>
                      <Input
                        name="userName"
                        type="text"
                        value={this.state.userName}
                        onChange={this.handleChange}
                        placeholder="Your full name"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="contactNumber" className="form-input">Contact Number</Label>
                      <Input
                        name="contactNumber"
                        type="text"
                        value={this.state.contactNumber}
                        onChange={this.handleChange}
                        placeholder="Phone number"
                        maxLength={26}
                    />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userRequest" className="form-input">Order Request</Label>
                      <Input
                        name="userRequest"
                        type="textarea"
                        value={this.state.userRequest}
                        onChange={this.handleChange}
                        placeholder="Fill in your order request here"
                        maxLength={700}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="submit" name="signup" id="signup"  style={{color: 'white'}} className="btn btn-primary" value="Submit Request" />
                    </FormGroup>
                  </Form>
                  </div>
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
      );
    }
}
export default AutoParts;
