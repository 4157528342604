import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/eswatini-shipping-map.jpg'
import CountryImage from './../../assets/single-elements/blog-images/swaziland-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToSwaziland extends Component {

  render() {
    const title = "Importation of used cars to Eswatini from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Eswatini"
    const pubDate = "10 May - 5 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Shipping a car from UK to Swaziland</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a used car from the UK to Swaziland" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page blogs-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" >
                    <BlogBlueprint
                      title={title}
                      bImage={CountryImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Supplier of brand new and pre-owned vehicles from the UK to our clients based in Eswatini.
                      CHICARGOS is rated as the #1 vehicle exporter to the African country.
                    </p>
                    <br/>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      To order a vehicle, browse the available stock <a className="a-links" href="/">
                      using the search feature here </a>
                      <br/>
                      OR
                      <br/>
                      Submit an import enquiry request below for a quote on any vehicle available on the UK market.
                      <br/>
                    </p>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px' }}>
                      Car shipping service to Mbabane, Eswatini from UK</p>

                    <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>

                      <p>
                        Methods used to ship vehicles include; RORO (Roll-on Roll-off) and container shipment option.
                        RORO method is most popular whereas container is arranged upon customer’s request.
                        CHICARGOS will collate and prepare
                        all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                        Marine Insurance Documents, Receipts and any other documents required for shipping, and process
                        these on your behalf
                      </p>
                      <p>
                        Eswatini is landlocked thus CHICARGOS offers a shipped and insured service to the following ports::
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Durban: </span>
                        Customer is responsible for picking up the vehicle from the port
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Maputo: </span>
                        Customer is responsible for picking up the vehicle from the port
                      </p>
                      <div style={{
                        marginTop: "25px",
                        height: '350px',
                        backgroundImage: `url(${bImage})`
                      }} className="help-department-heading"/>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Importing a vehicle from the UK takes approximately 21-24 days;
                      we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                      CHICARGOS will ship the vehicle and send relevant documentation via DHL.
                    </p>
                    <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                      Documentation required at importation by the Eswatini Revenue Authority (SRA) for clearance purposes:
                    </p>
                    <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                      <ul>
                        <li>
                          DA 304S form
                        </li>
                        <li>
                          Original car carrier’s invoice to the sea port (inland freight);
                        </li>
                        <li>
                          Original Customs Export Bill of Entry;
                        </li>
                        <li>
                          Bill of lading
                        </li>
                        <li>
                          Invoice or letter of sale indicating the price paid
                        </li>
                        <li>
                          Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                          These may include certificate of registration, police clearance certificate and Export Certificate.
                        </li>
                        <li>
                          Spare key (if available)
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Eswatini </p>
                    <p className="page-information"
                       style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      <span style={{fontWeight: 700}}>The following should be regarded as a guide only, </span>
                      as the final assessment is given by the Eswatinin Revenue Authorities at the time of clearance
                      at the border.
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '10px'}}>
                      The duty to be paid on importation of motor vehicles into Eswatini is based on the value of the
                      vehicle (FOB)
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      Before you can register your car in Eswatini, you have to pay
                      Customs duties (0 - 40%), and VAT (14%).
                    </p>

                    <div>
                      <br/>
                      <br/>
                      <h4 className="page-header-content" style={{ color: '#b60811', textAlign: 'center'}}>FAQs</h4>
                      <br/>
                      <div style={{ color: '#424242'}}>
                        <p style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>How much does it cost to ship a
                          used car from UK to Lobamba, Eswatini?</p>
                        <div style={{fontSize: '15px', color: '#424242'}}>Shipping charge is calculated based on the
                          size of the vehicle. Use the table below for reference.
                        </div>
                        <div className="container" style={{fontSize: '15px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Vehicle Type</th>
                              <th>Durban</th>
                              <th>Maputo</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Saloon
                              </td>
                              <td>from $900</td>
                              <td>from $800</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                SUV, 4x4 (Pick Up)
                              </td>
                              <td>from $1100</td>
                              <td>from $1200</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Van
                              </td>
                              <td>from $950</td>
                              <td>from $1000</td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <p style={{fontSize: '16px', fontWeight: 700}}>
                          How much are vehicle import duties and taxes in Eswatini?
                        </p>
                        <div style={{fontSize: '15px'}}>
                          The following should be regarded as a guide only, as the final assessment is given by the
                          Swaziland Revenue Authority at the time of clearance at the border.
                          <br/>
                          <span style={{fontWeight: 700}}>Customs Duties range 0 - 40% </span>
                          is payable on the Customs Value of the second hand car.
                          <br/>
                          <span style={{fontWeight: 700}}>VAT of 14% </span>
                          is payable on customs value of the imported vehicle.
                        </div>

                      </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px', textAlign: 'center'}}>
                      Quick Import enquiry request </p>
                    <div>
                      <ImportRequestForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}

export default ImportCarToSwaziland;
