import React, { Component } from "react";
import Header from "./HeaderComponent";
import Home from "./HomeComponent";
import Login from "./../containers/LoginComponentContainer";
import Profile from "./../containers/ProfileContainer";
import Car from "./../containers/CarComponentContainer";
import SearchResult from "./../containers/searchResultContainer";
import SearchPartnerEB from "./../containers/searchResultEBContainer"
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "./FooterComponent";
import Cars from "./Cars";
import CommercialVehicles from "./CommercialVehicles";
import AutoParts from "./AutoParts";
import HelpContact from "./Help-contact";
import LocalServices from "./ServiceLocal";
import Electronics from "./Electronics";
import ProductDetails from './../containers/ProductDetailsContainer';
import ItemDetails from "./../containers/ItemDetailsContainer"
import HowToBuy from './HowToBuy'
import StockProcurement from './StockProcurement'
import AboutUs from './AboutUs'
import NotFound from './NotFound'
import TheExperience from './blogs/TheExperience'
import CorperateNationalReps from './Careers/CorperateNationalReps'
import PartnerProgrammeComponent from './PartnerProgramme/PartnerProgrammeComponent'
import HowPartnerProgramme from './PartnerProgramme/HowPartnerProgramme'
import CompensationPartnerProgramme from './PartnerProgramme/CompensationPartnerProgramme'
import ToolsPartnerProgramme from './PartnerProgramme/ToolsPartnerProgramme'
import SupporterDashboardComponent from './Profile/SupporterDashboardComponent'
import UserBasket from "./../containers/UserBasketContainer"
import PasswordResetComponent from "./../containers/PasswordResetContainer"
import ResendActivationComponent from "./../containers/ResendActivationContainer"
import ActivateConfirmComponent from "./../containers/ActivateConfirmContainer"
import ImportVehicleFromAfrica from './blogs/ImportVehicleFromAfrica'
import MarketingTechnicals from './Careers/MarketingTechnicalTest'
import ImportCarToZambia from './blogs/ImportCarToZambia'
import ImportCarToBotswana from './blogs/ImportCarToBotswana'
import ImportCarToZimbabwe from "./blogs/ImportCarToZimbabwe";
import ImportCarToMauritius from "./blogs/ImportCarToMauritius"
import ImportCarToMalawi from "./blogs/ImportCarToMalawi"
import ImportCarToNamibia from "./blogs/ImportCarToNamibia"
import ImportCarToSwaziland from "./blogs/ImportCarToSwaziland";
import ImportCarToMozambique from "./blogs/ImportCarToMozambique";
import ImportCarToLesotho from "./blogs/ImportCarToLesotho";
import ImportCarToTanzania from "./blogs/ImportCarToTanzania";
import ImportCarToKenya from "./blogs/ImportCarToKenya"
import ScrollToTop from "./ScrollToTop"
import BannerEmail from "./BannerEmailCampaign";
import FlashDiscount from "./FlashDiscountLanding";
import AdLanding from "./AdLandingComponent";
import SearchList from "./Search/SearchList";
import AddContentComponent from "./../containers/CreateContentContainer"
import CreateCourseComponent from "./../containers/CreateCourseContainer"

import { matchPath } from 'react-router-dom';


import Analytics from 'react-router-ga';

class Main extends Component {
  render() {
    return (
      <div>
        {/*<BannerEmail/>*/}
        {/*<Header />*/}
        <Analytics id="UA-153402610-5">
          <ScrollToTop>
            <Route
              children={({ location }) => {
                // return !["/vendor/add-content/11"].includes(location.pathname)
                return !matchPath(location.pathname, {path: '/vendor/add-content/:id'})
                  ? (
                    <>
                      <Header />
                    </>
                  )
                  : null;
              }}
            />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Login" exact component={Login} />
            <Route path="/login-a" exact component={Login} />
            <Route path="/login-b" exact component={Login} />
            <Route path="/login-c" exact component={Login} />
            <Route path="/auth-reset" exact component={Login} />
            <Route
              path="/Car/:id"
              exact
              component={props => <Car {...props} MultiView={0} />}
            />
            <Route
              path="/activate/:uid/:token"
              exact
              component={ActivateConfirmComponent}
            />
            <Route
              path="/checkout"
              exact
              component={props => <Car {...props} MultiView={1} />}
            />
            <Route
              path="/review-order"
              exact
              component={props => <Car {...props} MultiView={3} />}
            />
            <Route
              path="/password/reset/confirm/:uid/:token"
              exact
              component={PasswordResetComponent}
            />
            <Route
              path="/resend-activation-link"
              exact
              component={ResendActivationComponent}
            />
            <Route
              path="/confirm-order"
              exact
              component={props => <Car {...props} MultiView={2} />}
            />
            {/*<Route path="/profile" exact component={Profile} />*/}
            <Route path="/profile" exact component={ Profile('ProfileComponent') } />
            <Route path="/my-downline" exact component={ Profile('DownlineComponent') } />
            <Route path="/advertising-tools" exact component={ Profile('AdvertisingComponent') } />
            <Route path="/supporter-training" exact component={ Profile('TrainingComponent')} />
            <Route
              path="/supporter-dashboard"
              exact component={ Profile('SupporterDashboardComponent')} />
            <Route
              path="/vendor-dashboard"
              exact component={ Profile('VendorDashboardComponent')} />
            <Route path="/my-basket" extact component={UserBasket} />
            <Route
              path="/search/:page/:min/:max/:st/:sv/:svm/:order"
              exact
              component={SearchResult}
            />
            <Route path="/partner-one-search/:q/:min/:max/:page/:sort" component={SearchPartnerEB} />
            <Route path="/search/:stage/:level?/:subject?"
                   render={(props) => <SearchList {...props} />}
            />
            <Route path="/partner-one-search-cat/:q/:min/:max/:page/:sort/:type" component={SearchPartnerEB} />
            <Route
              path="/details/:id/:lectureId?"
              exact
              component={ProductDetails}
            />
            <Route
              path="/item-details/:id/:price"
              exact
              component={ItemDetails}
            />
            {/*<Route path="/cars" component={Cars} />*/}
            <Route path="/auto-parts" component={AutoParts} />
            <Route path="/vendor/add-content/:cid/:lid" component={AddContentComponent} />
            <Route path="/vendor/add-content/:cid" component={AddContentComponent} />
            <Route path="/vendor/create-product" component={CreateCourseComponent} />
            <Route path="/local-services" component={LocalServices} />
            <Route path="/help-contact" component={HelpContact} />
            <Route path="/commercial-vehicles" Component={CommercialVehicles} />
            <Route path="/electronics" component={AboutUs} />
            <Route path="/how-to-buy" component={HowToBuy} />
            <Route path="/bulk-stock-procurement" component={StockProcurement} />
            <Route path="/about-us" component={AboutUs}  />
            <Route path="/flash-discount" component={FlashDiscount} />
            <Route
              path="/flash-discount-confirm"
              render={(props) => <FlashDiscount {...props} discountSuccess={true} />}
            />
            <Route path="/best-export-deals/landing" render={(props) => <AdLanding country="ZIMBABWE" {...props} />} />
            <Route path="/best-export-deals/g-landing" render={(props) => <AdLanding country="ZIMBABWE" {...props} />} />
            <Route path="/best-export-deals/zambia" render={(props) => <AdLanding country="ZAMBIA" {...props} />} />
            <Route path="/best-export-deals/kenya" render={(props) => <AdLanding country="KENYA" {...props} />} />
            <Route path="/best-export-deals/malawi" render={(props) => <AdLanding country="MALAWI" {...props} />} />
            <Route path="/best-export-deals/tanzania" render={(props) => <AdLanding country="TANZANIA" {...props} />} />
            <Route path="/best-export-deals/uganda" render={(props) => <AdLanding country="UGANGA" {...props} />} />
            <Route path="/best-export-deals/mozambique" render={(props) => <AdLanding country="MOZAMBIQUE" {...props} />} />
            <Route path="/best-export-deals/botswana" render={(props) => <AdLanding country="BOTSWANA" {...props} />} />
            <Route path="/blog/chicargos-experience" component={TheExperience}/>
            <Route path="/blog/import-a-used-car-to-africa-online" component={ImportVehicleFromAfrica}/>
            <Route path="/article/import-car-to-zambia-from-uk" component={ImportCarToZambia}/>
            <Route path="/article/import-car-to-botswana-from-uk" component={ImportCarToBotswana}/>
            <Route path="/article/import-car-to-zimbabwe-from-uk" component={ImportCarToZimbabwe}/>
            <Route path="/article/import-car-to-mauritius-from-uk" component={ImportCarToMauritius}/>
            <Route path="/article/import-car-to-malawi-from-uk" component={ImportCarToMalawi}/>
            <Route path="/article/import-car-to-namibia-from-uk" component={ImportCarToNamibia}/>
            <Route path="/article/import-car-to-eswatini-from-uk" component={ImportCarToSwaziland}/>
            <Route path="/article/import-car-to-mozambique-from-uk" component={ImportCarToMozambique}/>
            <Route path="/article/import-car-to-lesotho-from-uk" component={ImportCarToLesotho}/>
            <Route path="/article/import-car-to-tanzania-from-uk" component={ImportCarToTanzania}/>
            <Route path="/article/import-car-to-kenya-from-uk" component={ImportCarToKenya}/>
            <Route path="/careers/national-marketing-manager" component={CorperateNationalReps}/>
            <Route path="/careers/technical-assessment" component={MarketingTechnicals}/>
            <Route path="/supporter-programme/home" component={PartnerProgrammeComponent}/>
            <Route path="/supporter-programme/how" component={HowPartnerProgramme}/>
            <Route path="/supporter-programme/compensation" component={CompensationPartnerProgramme}/>
            <Route path="/supporter-programme/tools" component={ToolsPartnerProgramme}/>
            <Route component={NotFound} />
          </Switch>
          </ScrollToTop>
        </Analytics>
        <Footer />
      </div>
    );
  }
}

export default Main;
