import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Loading} from "../LoadingComponent";
import Typography from '@mui/material/Typography';
import DialogActions from "@mui/material/DialogActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Alert} from "reactstrap";

const ImageUpload = ({sendRequest, uploadSuccess, uploadImageError, uploadImageLoader}) => {

  const [image, setImage] = useState({ preview: "", file: "" });
  const [open, setOpen] = useState(false)
  const [upload, setUpload] = useState(false)
  const [url, setURL] = useState('')
  const [copied, setCopied] = useState(false)
  const [copyText, setCopyText] = useState('Copy')


  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  };

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const onUploadAnother = () => {
    setURL('')
    setImage({ preview: "", file: "" })
    setCopied(false)
    setCopyText('Copy')
  }

  useEffect(() => {
    if (upload === true) {
      if (image.file && image.file) {
        const formData = new FormData();
        formData.append("image", image.file);
        sendRequest(formData)
        setUpload(false)
      }
      else {
        setUpload(false)
      }
    }

  }, [upload]);

  useEffect(()=> {
    if (uploadSuccess && uploadSuccess.data) {
      setURL(uploadSuccess.data.url)
    }
  }, [uploadSuccess && uploadSuccess.data])

  useEffect(()=> {
    if (copied){
      setCopyText('Copied')
    }
  }, [copied])

  useEffect(()=> {
    setURL('')
  }, [])


  return (
    <div style={{paddingBottom: '24px'}}>
      <Button
        disableElevation
        variant="contained"
        onClick={() => handleDialogOpen()}
        className="mui-buttons"
      >
        Get Image Link
      </Button>
      <Dialog
        open={open}
        onClose={()=> setOpen(false)}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle style={{fontWeight: 800, fontSize: "18px"}}>Create image URL</DialogTitle>
        <DialogContent>
          {uploadImageError ?
            (<Alert color="danger">
              Oops, something has gone wrong, make sure you're logged in
              </Alert>)
            :
            (null)}
          {url ?
            (<div>
              <input value={url} size="80" disabled={true}/>
              <CopyToClipboard text={url}
                               onCopy={() => setCopied(true)}>
                <Button>{copyText}</Button>
              </CopyToClipboard>
            </div>)
            :
            (<div>
              <div style={{margin: "8px 0px 24px 0px"}}>
                <Typography>Upload image to obtain URL </Typography>
              </div>
              {uploadImageLoader && (
                <div>
                  <Loading/>
                </div>
              )}
              {image.file && (
                <div>
                  <img alt="not found" width={"250px"} src={URL.createObjectURL(image.file)} />
                  <br />
                  <div style={{margin: "16px 0px 8px 0px"}}>
                    <Button
                      disabled={uploadImageLoader}
                      onClick={()=>setImage({ preview: "", file: "" })}>Remove</Button>
                  </div>

                </div>
              )}
              <br />
              <input
                type="file"
                name="myImage"
                disabled={uploadImageLoader}
                onChange={(event) => {
                  handleChange(event)
                }}
              />
            </div>)}
        </DialogContent>
        <DialogActions>
          {url ? (
            <div>
              <Button
                disableElevation
                variant="contained"
                onClick={onUploadAnother}
                style={{marginRight: "8px"}}
              >
                Upload another
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={()=> setOpen(false)}
              >
                Close
              </Button>
            </div>
            )
            : (
              <div>
                <Button
                  disableElevation
                  variant="contained"
                  style={{marginRight: "8px"}}
                  onClick={()=> setOpen(false)}
                >Cancel</Button>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={!(image.file && image.file) || uploadImageLoader}
                  onClick={()=>setUpload(true)}>Upload</Button>
              </div>
            )}

        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ImageUpload;