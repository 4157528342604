import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import {useEffect} from "react";
import {Loading} from "../LoadingComponent";

export default function AlertDialog({onConfirmDelete,
                                      currentLecture,
                                      onSuccess,
                                      onError,
                                      onLoading,
                                      lectureTitle}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(()=> {
    if (onSuccess && onSuccess.data) {
      setOpen(false)
    }
  }, [onSuccess && onSuccess.data])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} startIcon={<DeleteIcon />}>
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{fontWeight: 800}} id="alert-dialog-title">
          {"Delete confirmation"}
        </DialogTitle>
        {onLoading && (
          <div>
            <Loading/>
          </div>
        )}
        <div>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete <span style={{fontWeight: 800}}>{lectureTitle}</span> lecture?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={onLoading} onClick={handleClose}>No</Button>
          <Button disabled={onLoading} onClick={() => onConfirmDelete(currentLecture)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
