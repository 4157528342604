import React, {useEffect, useRef, useState} from 'react';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import LinearProgress from '@mui/material/LinearProgress';
import {convertToHTML} from 'draft-convert';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import RteTemplate from "./RteTemplate";
import StudioHeader from "./StudioHeader";
import StudioLoader from "./StudioLoader";
import {Col, Row, Table} from "reactstrap";
import Alert from '@mui/material/Alert';

import ResponsiveAppBar from "./ResponsiveAppBar"
import CreateLectureForm from "./CreateLectureForm"
import ImageUpload from "./ImageUpload";
import TextField from "@mui/material/TextField";
import CoverImageUpload from "./CoverImageUpload";
import DeleteActionConfirm from "./DeleteActionConfirm";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export default function AddContentComponent(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [multiEditor, setMultiEditor] = useState({rte1: EditorState.createEmpty()})
  const [value, setValue] = React.useState(0);
  const [prodTitle, setProdTitle] = useState('')
  const [prodCat, setProdCategory] = useState('')
  const [dateCreated, setDateCreated] = useState('')
  const itemRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [inputValues, setInputValues] = useState({})
  const [lectureCount, setLectureCount] = React.useState(1);
  const [updateDisabled, setUpdateDisabled] = React.useState(true)
  const [lectureId, setLectureId] = useState(undefined)
  const [lectureList, setLectureList] = useState([])
  const [courseId, setCourseId] = useState(null)
  const [coverImg, setCoverImg] = useState('https://www.open.edu/openlearn/pluginfile.php/3277384/tool_ocwmanage/articletext/0/become_a_student_inline.jpg')

  const [title, setTitle] = React.useState('');
  const [author, setAuthor] = React.useState('');

  const [openForm, setOpenForm] = React.useState(false);
  const [metaChanged, setMetaChange] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [publish, setPublish] = useState('draft');
  const [lectureTitle, setLectureTitle] = useState('')

  const handleClickOpen = () => {
    setOpenForm(true);
  };

  const handleDeleteContent = (lectureId) => {
    let payload = {lectureToDelete: lectureId}
    props.deleteContent(payload)
  }

  const handleChangePublish = (event) => {
    if (event.target.checked) {
      setPublish('live');
    } else {
      setPublish('draft')
    }
    setUpdateDisabled(false)
    setMetaChange(true)
  };

  const handleClickClose = () => {
    setOpenForm(false);
  };

  const handleFormChange = (event) => {
    if (event.target.id === 'title') {
      setTitle(event.target.value);
    } else if (event.target.id === 'author') {
      setAuthor(event.target.value)
    } else if (event.target.id === 'subtitle') {
      setSubtitle(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'description'){
      setDescription(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'prodTitle'){
      setProdTitle(event.target.value)
      setMetaChange(true)
    }
    setUpdateDisabled(false)
  };

  const onFormSubmit = () => {
    let payload = {title: title, author: author, courseId: courseId}
    props.createLecture(payload)
  };

  const filterCurrentLecture = (resObj) => {
    let currLectureId = [parseInt(props.match.params.lid)]
    return resObj.filter(function (item) {
      return currLectureId.indexOf(item.lecture_id) > -1;
    })
  }

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    if (props.postContentSuccess && props.postContentSuccess) {
      props.getContent(contentObj)
    }
  }, [props.postContentSuccess && props.postContentSuccess.data])

  useEffect(() => {
    if (props.createLectureSuccess && props.createLectureSuccess) {
      setLectureList(props.createLectureSuccess.data.lecture_list)
      setOpenForm(false)
    }
  }, [props.createLectureSuccess && props.createLectureSuccess])

  useEffect(() => {
    if (props.postContentLoader === true) {
      setOpen(true)
      setUpdateDisabled(true)
    }
  }, [props.postContentLoader])

  useEffect(() => {
    if (props.deleteContentSuccess !== null ) {
      let contentObj = {cid: courseId, lid: undefined}
      if (props.match.params.lid) {
        props.history.push(`/vendor/add-content/${courseId}`)
      } else {
        props.getContent(contentObj)
      }
      setValue(1)
    }
  }, [props.deleteContentSuccess && props.deleteContentSuccess.data])

  useEffect(() => {
    if (props.getContentSuccess && props.getContentSuccess) {
      setProdTitle(props.getContentSuccess.data.prod_title)
      setSubtitle(props.getContentSuccess.data.prod_subtitle)
      setDescription(props.getContentSuccess.data.prod_description)
      setProdCategory(props.getContentSuccess.data.cat_full_name)
      setPublish(props.getContentSuccess.data.prod_live)
      setDateCreated(props.getContentSuccess.data.date_created)
      setLectureList(props.getContentSuccess.data.lecture_list)
      if ((props.getContentSuccess.data.lecture_list).length > 0 && props.match.params.lid !== undefined) {
        let currLec = filterCurrentLecture(props.getContentSuccess.data.lecture_list)
        setLectureTitle(currLec[0].title)
      }
      setCoverImg(props.getContentSuccess.data.cover_image)
      if (Object.keys(props.getContentSuccess.data).length > 0) {
        if (props.match.params.lid) {
          let contentObj = {}
          for (let [key, value] of Object.entries(props.getContentSuccess.data.editors)) {
            if (key.endsWith("_raw")) {
              let con = convertFromRaw(JSON.parse(value))
              let con_conv = EditorState.createWithContent(con)
              let ed_key = key.split("_")[0]
              contentObj[ed_key] = con_conv
              contentObj[key] = value
            }
          }
          setInputValues(props.getContentSuccess.data.lectures)
          setUpdateDisabled(true)
          setMultiEditor(contentObj)
        }
        setOpen(false)
      }
    }

  }, [props.getContentSuccess && props.getContentSuccess.data])

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    setLectureId(lectureID)
    setCourseId(contentID)
    if (contentID !== null) {
      props.getContent(contentObj)
    }
    if (lectureID !== undefined){
      setValue(2)
    }

  }, [])

  useEffect(() => {
    if (itemRef && itemRef.current) {
      window.scrollTo({
        top: itemRef.current.offsetTop - 300,
      });
    }
  },[value]);

  const handleClose = () => {
    setOpen(false);
  };

  const onEditorStateChange = (editorState, rteName) => {
    let raw = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    let html = convertToHTML(editorState.getCurrentContent())
    let rawName = `${rteName}_raw`
    let htmlName = `${rteName}_html`
    setEditorState(editorState)
    setMultiEditor({
      ...multiEditor,
      [rteName]: editorState,
      [rawName]: raw,
      [htmlName]: html
    })
    setUpdateDisabled(false)
  }

  const handleLectureNameChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.id]: event.target.value
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLectureTitleChange = (event) => {
    setLectureTitle(event.target.value);
    setUpdateDisabled(false)
  };

  const handleSubmitContent = () => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {
      editors: multiEditor,
      lectures: inputValues,
      cid: contentID,
      lid: lectureID,
      lTitle: lectureTitle
    }
    if (metaChanged){
      contentObj['subtitle'] = subtitle
      contentObj['description'] = description
      contentObj['publish'] = publish
      contentObj['pTitle'] = prodTitle
    }
    props.postContent(contentObj)
  }

  return (
    <div>
      <ResponsiveAppBar
        disableButton={updateDisabled}
        onSubmit={handleSubmitContent}
      />

      {props.postContentLoader && props.postContentLoader ?
        <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box> :
        null
      }
      <div style={{backgroundColor: "white", marginTop: "64px"}}>
        <div>
          <Box
            className="studio-box"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: 364}}
          >
            <StudioLoader onClose={handleClose} open={open}/>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#ff4900",
                  width: 4
                }
              }}
              sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: 'whitesmoke', paddingTop: '0px'}}
            >
              <Tab className="vertical-tabs-button" label="Course" {...a11yProps(0)} />
              <Tab className="vertical-tabs-button" label="Lectures" {...a11yProps(1)} />
              <Tab className="vertical-tabs-button" label="Studio" {...a11yProps(2)} />
              {/*<Tab className="vertical-tabs-button" label="Publish" {...a11yProps(3)} />*/}

            </Tabs>
            <TabPanel className="studio-panel" value={value} index={0} style={{width: "80%"}}>
              <div>
                <StudioHeader
                  title="Course Details"
                  info="This is the course details section"
                />
              </div>
              <div style={{padding: "0px 0px 32px 0px"}}>
                <Row>
                  <Col sm="12">
                    <Table bordered striped size="md" >
                      <tbody>
                      <tr>
                        <td className="tbl-header">Course name:</td>
                        <td className="tbl-data">{prodTitle}</td>
                      </tr>
                      <tr>
                        <td className="tbl-header">Publish:</td>
                        <td className="tbl-data">
                          <Switch
                            checked={publish === 'live'}
                            onChange={handleChangePublish}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="tbl-header">Date created:</td>
                        <td className="tbl-data">{dateCreated}</td>
                      </tr>
                      <tr>
                        <td className="tbl-header">Category:</td>
                        <td className="tbl-data">{prodCat}</td>
                      </tr>
                      <tr>
                        <td className="tbl-header">Image:</td>
                        <td className="tbl-data">
                          <Col sm="6" style={{padding: "16px"}}>
                            <img alt="Course img" width={"150px"} src={coverImg} />
                          </Col>
                          <Col sm="6" >
                            <CoverImageUpload
                              sendRequest={props.uploadImage}
                              uploadSuccess={props.uploadImageSuccess}
                              uploadImageError={props.uploadImageError}
                              uploadImageLoader={props.uploadImageLoader}
                              updateCoverImg={setCoverImg}
                              courseId={courseId}
                            />
                          </Col>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                    <TextField
                      fullWidth
                      id="prodTitle"
                      label="Enter course topic - (max 100 characters)"
                      value={prodTitle}
                      onChange={handleFormChange}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 100 }}
                      style={{margin: "64px 0px 16px 0px", backgroundColor: "white"}}
                    />
                    <TextField
                      fullWidth
                      id="subtitle"
                      label="Enter course subtitle - (max 100 characters)"
                      value={subtitle}
                      onChange={handleFormChange}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 100 }}
                      style={{margin: "32px 0px", backgroundColor: "white"}}
                    />
                    <TextField
                      fullWidth
                      id="description"
                      label="Enter course description"
                      value={description}
                      onChange={handleFormChange}
                      multiline
                      variant="outlined"
                      rows={3}
                      maxRows={4}
                      style={{margin: "8px 0px", backgroundColor: "white"}}
                    />
                  </Col>
                </Row>
              </div>
            </TabPanel>
            <TabPanel className="studio-panel" value={value} index={1} style={{ width: "80%"}}>
              <div>

                <StudioHeader
                  title="Lectures"
                  info="A list of lectures"
                />
                <Row style={{ paddingBottom: '8px' }}>
                  <Col style={{paddingLeft: '0px'}}>
                    <div>
                      <CreateLectureForm
                        handleFormChange={handleFormChange}
                        title={title}
                        author={author}
                        onFormSubmit={onFormSubmit}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClickClose}
                        open={openForm}
                      />
                    </div>
                  </Col>
                  <Col>
                  </Col>
                </Row>
                <Col sm="12">
                  <Table bordered >
                    <tbody>

                    </tbody>
                  </Table>
                  <Table bordered striped>
                    <thead>
                    <tr>
                      <th>Lecture ID</th>
                      <th>Author</th>
                      <th>Title</th>
                      <th>Date Created</th>
                      <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {lectureList.map((lecture) => (
                      <tr>
                        <th scope="row">{lecture.lecture_id}</th>
                        <td><a href={`/vendor/add-content/${courseId}/${lecture.lecture_id}`}>{lecture.author} </a></td>
                        <td><a href={`/vendor/add-content/${courseId}/${lecture.lecture_id}`}>{lecture.title} </a></td>
                        <td>{lecture.date_created}</td>
                        <td>
                          <DeleteActionConfirm
                            onConfirmDelete={handleDeleteContent}
                            currentLecture={lecture.lecture_id}
                            onSuccess={props.deleteContentSuccess}
                            onError={props.deleteContentError}
                            onLoading={props.deleteContentLoader}
                            lectureTitle={lecture.title}
                          />
                          {/*<Button*/}
                          {/*  onClick={(event) => handleDeleteContent(lecture.lecture_id)}*/}
                          {/*  variant="contained"*/}
                          {/*  disableElevation startIcon={<DeleteIcon />}>*/}
                          {/*  Delete*/}
                          {/*</Button>*/}
                        </td>
                        {/*<a href={`/vendor/add-content/${product.prod_id}`}>{product.prod_name}</a>*/}
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </Col>
              </div>
            </TabPanel>
            <TabPanel className="studio-panel" value={value} index={2} style={{ width: "80%"}}>
              <div ref={value === 2 ? itemRef : null}>
                {lectureId !== undefined ?
                  <div>
                    <StudioHeader
                      title={"Content: " + lectureTitle}
                      info="This is where you add content to your lecture"
                    />
                    <ImageUpload
                      sendRequest={props.uploadImage}
                      uploadSuccess={props.uploadImageSuccess}
                      uploadImageError={props.uploadImageError}
                      uploadImageLoader={props.uploadImageLoader}
                    />
                    <div
                      style={{
                        backgroundColor: "#f7f9fa",
                        borderRadius: "8px",
                        marginBottom: "64px"
                      }}>
                      <RteTemplate
                        lectureName={inputValues["inline-basic-1"]}
                        handleLectureNameChange={handleLectureNameChange}
                        editorState={multiEditor["rte1"]}
                        onEditorStateChange={onEditorStateChange}
                        lectureCount={1}
                        eventName={"inline-basic-1"}
                        rteName={"rte1"}
                        lectureTitle={lectureTitle}
                        handleLectureTitleChange={handleLectureTitleChange}
                      />
                    </div>
                  </div>
              :
                  (<div style={{padding: "24px 0px"}}>
                    <Alert variant="filled" severity="warning">
                      Select a lecture from list
                    </Alert>
                  </div>)
              }
              </div>

            </TabPanel>
            {/*<TabPanel className="studio-panel" value={value} index={3}>*/}
            {/*  <StudioHeader*/}
            {/*    title="Publish"*/}
            {/*    info="Publish your course"*/}
            {/*  />*/}
            {/*</TabPanel>*/}
          </Box>
        </div>
      </div>
      <Box sx={{ width: 500 }}>
      </Box>
    </div>

  )
}
