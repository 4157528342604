import React from "react";
import {Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";


const routeToDetails = (props) => {
    const { history } = props;
    history.push(`${props.d.id}`);
};

const PromotionUpSellingStatic = (props) => {
   return(
     <div style={{cursor: 'pointer'}} onClick={() => routeToDetails(props)} className="col-lg-2 col-md-4 col-6 mt-4" key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive"
           src={props.d.remote_image}
           alt="Card image cap"
         />
         <CardBody style={{ textAlign: 'center' }}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle>
                {/*eslint-disable-next-line */}
               <div className="link-text" style={{ paddingBottom: '10px', fontSize: '15px'}}>
                 {props.d.title}
               </div>{' '}
             </CardSubtitle>
             <CardText style={{color: '#42424242', fontWeight: 900, fontStyle: 'italic'}}>
               {'From '}
                 {/*eslint-disable-next-line */}
               <a
                 href="#"
                 className="home-price"
               >
                 <NumberFormat
                   value={props.d.price}
                   displayType={'text'}
                   thousandSeparator={true}
                   prefix={'$'}
                   suffix={'.00'}
                 />
               </a>
             </CardText>
           </div>
         </CardBody>
       </Card>
      </div>
    );

}

export default withRouter(PromotionUpSellingStatic);