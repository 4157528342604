import {Button, Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import {Editor} from "react-draft-wysiwyg";
import {convertFromRaw, EditorState} from "draft-js";

const LectureViewComponent = (props) => {
  return(
    <div>
      <div>
        <div style={{
          padding: '16px 16px',
          backgroundColor: 'whitesmoke',
          borderRadius: '8px',
          margin: '40px 0px'
        }}
          // ref={activeSlideRef.current[obj.id]}
          //    key={obj.id}
        >
          <div>
            <div style={{paddingBottom: '16px'}}>
              <Typography className='overview-headings' style={{fontWeight: 800}}>
                {props.o.title}
              </Typography>
              <div style={{fontStyle: 'italic', fontSize: '12px'}}>
                By, {props.o.author}
              </div>
            </div>
            <div >
              <Typography className='what-you-will-learn' style={{fontWeight: 700, fontTransform: 'capitalize'}}>
                What you will learn:
              </Typography>
              <Typography className='what-you-will-learn'>
                {props.o.content.lectures['inline-basic-1']}
              </Typography>
            </div>
          </div>

        </div>
        <div style={{padding: '8px 8px 16px 16px'}} className="lecture-view">
          <Editor readOnly={true}
                  toolbarHidden={true}
                  editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(props.o.content.editors.rte1_raw)))}/>
        </div>

      </div>
    </div>
  );

}

export default withRouter(LectureViewComponent);