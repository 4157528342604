import React, {Component} from 'react';
import BlogTemplate from './../BlogTemplate'
import CoverImage from './../../assets/single-elements/blog-images/photo-exukonline-experience.jpeg'
import {Helmet} from "react-helmet";



class TheExperience extends Component {

  render() {
    const title = "The CHICARGOS Experience"
    const bImage = CoverImage
    const subHeading = "Purchasing a vehicle online: What to look out for"
    const paragraph1 = "A car is a serious investment, just like a house and other similar examples. This is why when it’s time to make that life-changing purchase you cannot take choosing where to buy from lightly. | \n" +
      "There are thousands of car dealers who offer either used or new vehicles and sometimes both. With such a vast array of choices, it’s normal to be a bit overwhelmed. Especially when you can’t simply pick the first car dealership that comes to mind. There are factors to consider to avoid getting a bad deal or making a regrettable purchase. Here are some of those factors:|\n" +
      "The dealership’s reputation – are they known for good service and honesty? Do you know anyone that has made a successful purchase from them? What do most of their clients have to say? Always check the background of the car dealership for any red flags.| \n" +
      "Do they have what you want – take a peek at their selection of cars for sale. Ensure you are confident you can find what you need with the dealership you choose.|\n" +
      "Customer service – buying a car is hardly a walk in the park but some dealerships can easily make it seem that way. Try to get some insight on the dealership’s customer service. Are they helpful, patient, transparent or just in a hurry to take your money and push any vehicle your way?|"
    const subHeading2 = "CHICARGOS offer"
    const paragraph2 = "Buying a vehicle on-line requires much more reassurance than other on-line purchases. This is where we come into our own, here at CHICARGOS buyers have the security of knowing their cars are being supplied by one of the UK's leading dealer groups |\n" +
      "Our site enables customers to search a wide range of vehicles and benefit from the discounts manufacturers make available to car dealers . Our follow up service will ensure that, if there is a better deal available on the day, we can get this information to you. We ensure that you have the dealers 'BestPrice Quote' so it really is all you need to buy your vehicle with confidence. |\n" +
      "CHICARGOS is a reputable car dealership who focus on the exportation and sales of commercial, luxury and " +
      "performance vehicles from the UK. We stock a large variety of cars to ensure everyone can find what they need. " +
      "When you reach out or make a purchase on vehicles, automobile parts or goods from us, you can be rest assured of " +
      "the best customer service, transparency, and reliability. We are professional and approachable in our service to" +
      " you always. Buying a car from CHICARGOS is an extremely easy and quick process. Here is a look at what to expect.| \n"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Importation Guide</title>
          <meta name="description"
                content="CHICARGOS Blog- Buy with confidence" />
        </Helmet>
        <BlogTemplate
          title={title}
          bImage={bImage}
          subHeading={subHeading}
          paragraph1={paragraph1}
          subHeading2={subHeading2}
          paragraph2={paragraph2}>
        </BlogTemplate>
      </div>
    )
  }

}

export default TheExperience;
